import React from "react";

import BookDemo from "../../../views/BookDemo";
import Modal from "react-bootstrap/Modal";

const BookDemoModal = ({
  handleClose,
  show,
  time,
  setTime,
  setShow,
  setShowOtp,
  formValue,
  setFormValue,
  startTimer,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      id="bookDemoModalToggle"
    >
      <Modal.Header>
        <i
          className="fas fa-times"
          data-bs-dismiss="modal"
          aria-label="Close" onClick={handleClose}
        ></i>
      </Modal.Header>

      <Modal.Body className="ps-5 pe-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="ms-5 me-5 text-center">
              <h5>Book a Demo</h5>
              <p className="mt-3">
                Curious to see how OpticalHR can streamline your HR processes
                and empower your team? Book a demo with us today
              </p>
            </div>
          </div>
          <div className="col-lg-12">
            <BookDemo
              time={time}
              setTime={setTime}
              setShow={setShow}
              setShowOtp={setShowOtp}
              startTimer={startTimer}
              formValue={formValue}
              setFormValue={setFormValue}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BookDemoModal;
