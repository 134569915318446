import React from "react";
import ReactCaptchaa from "react-captchaa";

const ContactForm = ({
  formValue,
  handleSubmit,
  handleChange,
  formErrors,
  setGeneratedCaptcha,
  isFormValid,
}) => {
  return (
    <div className="col-lg-6 mb-3">
      <div className="row">
        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            Name <span className="mandatory">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="Enter Name"
            value={formValue.name}
            onChange={handleChange}
          ></input>
          {formErrors.name && (
            <div className="text-danger">{formErrors.name}</div>
          )}
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            Email <span className="mandatory">*</span>
          </label>
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Enter Email"
            value={formValue.email}
            onChange={handleChange}
          />
          {formErrors.email && (
            <div className="text-danger">{formErrors.email}</div>
          )}
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            Phone Number <span className="mandatory">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Phone Number"
            name="phoneNumber"
            value={formValue.phoneNumber}
            onChange={handleChange}
          />
          {formErrors.phoneNumber && (
            <div className="text-danger">{formErrors.phoneNumber}</div>
          )}
        </div>
        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            Company Name <span className="mandatory">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Company Name"
            name="companyName"
            value={formValue.companyName}
            onChange={handleChange}
          />
          {formErrors.companyName && (
            <div className="text-danger">{formErrors.companyName}</div>
          )}
        </div>
        <div className="col-lg-12 mb-3">
          <label className="mb-1">
            Message <span className="mandatory">*</span>
          </label>
          <textarea
            className="form-control"
            placeholder="Enter the Message"
            rows="3"
            name="message"
            value={formValue.message}
            onChange={handleChange}
          />
          {formErrors.message && (
            <div className="text-danger">{formErrors.message}</div>
          )}
        </div>
        <div className="col-lg-12 mb-3">
          <div className="d-flex">
            <ReactCaptchaa
              captchaText={(captchaText) => {
                setGeneratedCaptcha(captchaText);
              }}
              captchaLength={6}
              height={150}
              width={280}
              iconName={"FiRefreshCw"}
              iconColor={"#007057"}
              fontSize={"4em"}
              iconSize={"1em"}
              containerClassName={"react-captcha"}
              iconWrapperClassName={"react-captcha-icon-wrapper"}
              canvasClassName={"react-captcha-canvas"}
              iconClassName={"react-captcha-icon"}
              charactersInclude={
                "0123456789abcdefghijklmnopqrstuvwxzABCDEFGHIJKLMNOPQRSTUVWXYZ!#$%^&"
              }
            />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <label className="mb-1">
            Captcha <span className="mandatory">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Captcha"
            name="captcha"
            value={formValue.captcha}
            onChange={handleChange}
          />
          {formErrors.captcha && (
            <div className="text-danger">{formErrors.captcha}</div>
          )}
        </div>
        <div className="col-lg-12 mb-3">
          <button
            className="btn book-demo-btn mt-2 pt-0"
            href="#about"
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            Submit
            <span className="material-symbols-rounded">arrow_forward</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
