import React, { useRef, useEffect, useState } from 'react';
import whyOpticalHr1Img from "../src/assets/images/AI Powered Recruitment.jpg";
import whyOpticalHr2Img from "../src/assets/images/Time management.jpg";
import whyOpticalHr3Img from "../src/assets/images/Payroll Management.jpg";
import whyOpticalHr4Img from "../src/assets/images/Empower team.jpg";
import whyOpticalHr5Img from "../src/assets/images/Employee growth.jpg";

const Carousel = () => {
    const scrollerRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const handleScroll = (offset) => {
        scrollerRef.current.scrollBy({ left: offset, behavior: 'smooth' });
    };
    useEffect(() => {
        const scroller = scrollerRef.current;
        const firstElem = scroller.firstElementChild;
        const lastElem = scroller.lastElementChild;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.target === firstElem) {
                        setIsAtStart(entry.isIntersecting);
                    }
                    if (entry.target === lastElem) {
                        setIsAtEnd(entry.isIntersecting);
                    }
                });
            },
            { root: scroller, threshold: 0.5 }
        );
        observer.observe(firstElem);
        observer.observe(lastElem);
        return () => {
            observer.unobserve(firstElem);
            observer.unobserve(lastElem);
        };
    }, []);
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isAtEnd) {
                handleScroll(-516 * (items.length - 1));
            } else {
                handleScroll(516);
            }
        }, 3000); // Change slide every 3 seconds
        return () => clearInterval(intervalId);
    }, [isAtEnd]);
    return (
        <div className="carousel" aria-label="Featured Items Carousel" aria-roledescription="carousel">
            <div
                id="Scroller"
                className="scroller"
                role="group"
                aria-label="Items Scroller"
                aria-live="polite"
                ref={scrollerRef}
            >
                {items.map((item, index) => (
                    <a
                        href="/"
                        className="snap"
                        aria-label={`${index + 1} of ${items.length}`}
                        aria-roledescription="item"
                        key={index}
                    >
                        <figure>
                            <picture>
                                <img
                                    src={item.webp}
                                    alt=""
                                    loading="lazy"
                                    width="500"
                                    height="500"
                                />
                                <div className="slick-img-overlay">
                                    <h6>{item.caption}</h6>
                                    <p>{item.desc}</p>
                                </div>
                            </picture>
                        </figure>
                    </a>
                ))}
            </div>
        </div>
    );
};
const items = [
    {
        webp: `${whyOpticalHr1Img}`,
        caption: "AI-Powered Recruitment",
        desc: "Our Advanced Algorithms Match Candidates to Roles Based on Skills, Experience, and Culture."
    },
    {
        webp: `${whyOpticalHr2Img}`,
        caption: "Optimize Time Management",
        desc: "OpticalHR's Advanced Tracking Automates Attendance,Minimizes Errors, and Provides Valuable Workforce Insights."
    },
    {
        webp: `${whyOpticalHr3Img}`,
        caption: "Effortless Payroll Management",
        desc: "OpticalHR Automates Processes for Accuracy, Compliance,and Timely Payments, Letting HR Drive Strategy."
    },
    {
        webp: `${whyOpticalHr4Img}`,
        caption: "Empower Your Team",
        desc: "Our Self-Service Portal Lets Employees Manage Info,Payslips, Leaves, and Policies Easily, Boosting Transparency and Convenience."
    },
    {
        webp: `${whyOpticalHr5Img}`,
        caption: "Empower Employee Growth",
        desc: "OpticalHR's Scheduling Tool Streamlines Training Programs,Ensuring Timely Support and Development Opportunities."
    }
];
export default Carousel