import "./App.scss";
import logoImg from "../src/assets/images/logo.png";
import efficiencyImg from "../src/assets/images/Efficiency and Time Savings.png";
import improvedImg from "../src/assets/images/Improved Accuracy and Compliance.png";
import enhancedImg from "../src/assets/images/Enhanced Employee Experience.png";
import dataDrivenImg from "../src/assets/images/Data-Driven Decision Making.png";
import whyOpticalHr1Img from "../src/assets/images/AI Powered Recruitment.jpg";
import whyOpticalHr2Img from "../src/assets/images/Time management.jpg";
import whyOpticalHr3Img from "../src/assets/images/Payroll Management.jpg";
import whyOpticalHr4Img from "../src/assets/images/Empower team.jpg";
import whyOpticalHr5Img from "../src/assets/images/Employee growth.jpg";
import featuresImg1 from "../src/assets/images/Employee Database Management.png";
import featuresImg2 from "../src/assets/images/Self Service Portal.png";
import featuresImg3 from "../src/assets/images/Time & Attendance.png";
import featuresImg4 from "../src/assets/images/Recruitment and Applicant Tracking.png";
import featuresImg5 from "../src/assets/images/Payroll Management.png";
import featuresImg6 from "../src/assets/images/Leave Management.png";
import featuresImg7 from "../src/assets/images/Employee Training Schedules.png";
import devicesWhereYouImg from "../src/assets/images/devicesWhereYouSec.png";
import facebookImg from "../src/assets/images/facebook.svg";
import instagramImg from "../src/assets/images/instagram.svg";
import linkedinImg from "../src/assets/images/linkedin.svg";
import appStoreImg from "../src/assets/images/appstore.png";
import playStoreImg from "../src/assets/images/playstore.png";

import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookDemoModal from "./components/modals/BookDemoModal/BookDemoModal";
import VerifyYourIdentityModal from "./components/modals/VerifyYourIdentityModal/VerifyYourIdentityModal";
import ContactSales from "./views/contactSales";
import { Amplify, API } from "aws-amplify";
import awsconfig from "./aws-exports";
import { v4 as uuid } from "uuid";
import Carousel3D from "./carousel3d";
import Carousel from "./carousel";
Amplify.configure(awsconfig);

function App() {
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split(".")[0] + "Z";
  const formattedDemoDate = currentDate.toISOString();

  const DemoStatus = {
    Requested: "Requested",
  };

  const initialFormState = {
    pk: "DB#",
    id: uuid(),
    name: "",
    noOfEmployees: "",
    email: "",
    requirements: "",
    phoneNumber: "",
    companyName: "",
    country: "",
    status: DemoStatus?.Requested,
    requestedDate: formattedCurrentDate,
    demoDate: formattedDemoDate,
  };

  const [formValue, setFormValue] = useState(initialFormState);
  const [show, setShow] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [time, setTime] = useState({ minutes: 1, seconds: 30 });
  const intervalRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOtpClose = () => setShowOtp(false);
  const handleOtpShow = () => setShowOtp(true);

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime.seconds === 0) {
          if (prevTime.minutes === 0) {
            clearInterval(intervalRef.current);
            return { minutes: 0, seconds: 0 };
          } else {
            return { minutes: prevTime.minutes - 1, seconds: 59 };
          }
        } else {
          return { ...prevTime, seconds: prevTime.seconds - 1 };
        }
      });
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (responseData) {
      if (responseData.severity === "success") {
        toast.success("Successfully sent!", {
          theme: "colored",
          autoClose: true,
        });
        setFormValue(initialFormState);
      } else if (responseData.severity === "error") {
        toast.error("Failed to send. Please try again.", {
          theme: "colored",
          autoClose: true,
        });
        setFormValue(initialFormState);
      }
    }
  }, [responseData]);

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top" id="mainNav">
        <div className="container px-4">
          <a className="navbar-brand" href="#page-top">
            <img src={logoImg} alt="Logo Image" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto" id="top-menu">
              <li className="nav-item">
                <a className="nav-link active" href="#whyOpticalHr">
                  Why OpticalHR
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#features">
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#chooseYourPlan">
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#getInTouch">
                  Contact Sales
                </a>
              </li>
              <li className="">
                <a className="btn book-demo-btn" onClick={handleShow}>
                  Book a Demo
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* // <!-- Header-- > */}
      <header>
        <div className="container px-4 text-center">
          <h1>
            Bridging the Gap Between People and Potential<br></br>A
            Human-Centered Approach to Revolutionizing HR
          </h1>
          <p className="mt-4">
            Discover how our next-generation technology solutions can breathe
            new life into your HR strategies,turning them from mere systems into
            engines of human potential and organizational success.
          </p>
          <a className="btn book-demo-btn pt-0 mt-4" onClick={handleShow}>
            Book a Demo
            <span className="material-symbols-rounded">arrow_forward</span>
          </a>
        </div>
      </header>
      <section>
        <div className="container px-4">
          <div className="row gx-4 justify-content-center">
            <div className="col-lg-12">
              <div className="card web-img-card">
                <div className="card-body">
                  {/* <img src={webScreenImg3} alt="Web Screen Image" /> */}
                  <Carousel3D />
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-5">
              <div className="card featuers-img-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <img src={efficiencyImg} alt="Featuers Image" />
                      <p>Efficiency and Time Savings</p>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <img src={improvedImg} alt="Featuers Image" />
                      <p>Improved Accuracy and Compliance</p>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <img src={enhancedImg} alt="Featuers Image" />
                      <p>Enhanced Employee Experience</p>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <img src={dataDrivenImg} alt="Featuers Image" />
                      <p>Data-Driven Decision Making</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--whyOpticalHr section-- > */}
      <section id="whyOpticalHr">
        <div className="container px-4">
          <div className="row gx-4 justify-content-center">
            <div className="col-lg-4 mb-2 mob-mb-4">
              <h2>Why OpticalHR ?</h2>
              <p>
                In the ever-evolving landscape of business, the heartbeat of
                every organization lies within its people. Yet, navigating the
                complexities of HR in today's fast-paced world often feels like
                trying to steer a ship without a compass. Traditional methods,
                once reliable, now seem outdated and inefficient. That's where
                OpticalHR come in, offering not just a technological solution,
                but a human-centered revolution in HR
              </p>
              <p>
                Our mission is simple yet profound: to transform the way
                companies engage with their employees, fostering a sense of
                belonging and purpose that goes beyond the bottom line.
              </p>
              <a className="btn book-demo-btn pt-0" onClick={handleShow}>
                Book a Demo
                <span className="material-symbols-rounded">arrow_forward</span>
              </a>
            </div>
            <div className="col-lg-8 mb-2">
              <div className="large-screen-slider">
                <Carousel></Carousel>
              </div>
              <div className="myslider mobile-screen-slider">
                <div>
                  <img src={whyOpticalHr1Img} alt="AI Powered Recruitment" className="w-100" />
                  <div className="slick-img-overlay">
                    <h6>AI-Powered Recruitment</h6>
                    <p>
                      Our Advanced Algorithms Match Candidates to Roles Based on
                      Skills, Experience, and Culture.
                    </p>
                  </div>
                </div>
                <div>
                  <img src={whyOpticalHr2Img} alt="Time management" className="w-100" />
                  <div className="slick-img-overlay">
                    <h6>Optimize Time Management</h6>
                    <p>
                      OpticalHR's Advanced Tracking Automates Attendance,
                      Minimizes Errors, and Provides Valuable Workforce
                      Insights.
                    </p>
                  </div>
                </div>
                <div>
                  <img src={whyOpticalHr3Img} alt="Payroll management" className="w-100" />
                  <div className="slick-img-overlay">
                    <h6>Effortless Payroll Management</h6>
                    <p>
                      OpticalHR Automates Processes for Accuracy, Compliance,
                      and Timely Payments, Letting HR Drive Strategy.
                    </p>
                  </div>
                </div>
                <div>
                  <img src={whyOpticalHr4Img} alt="Empower team" className="w-100" />
                  <div className="slick-img-overlay">
                    <h6>Empower Your Team</h6>
                    <p>
                      Our Self-Service Portal Lets Employees Manage Info,
                      Payslips, Leaves, and Policies Easily, Boosting
                      Transparency and Convenience.
                    </p>
                  </div>
                </div>
                <div>
                  <img src={whyOpticalHr5Img} alt="Employee growth" className="w-100" />
                  <div className="slick-img-overlay">
                    <h6>Empower Employee Growth</h6>
                    <p>
                      OpticalHR's Scheduling Tool Streamlines Training Programs,
                      Ensuring Timely Support and Development Opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--large screen features-- > */}
      <section className="section" id="features">
        <div className="container px-4">
          <div className="row gx-4 large-screen-features-row">
            <div className="col-lg-12 text-center">
              <div className="card features-sec-card">
                <div className="card-body ps-0 pe-0">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <h2>Features</h2>
                      <p>
                        Cultivate Success with OpticalHR - Elevate Your HR
                        Management to New Heights. Our platform empowers
                        businesses with streamlined processes, enhanced
                        efficiency, and unparalleled insights. Experience cost
                        savings, compliance assurance, and increased employee
                        satisfaction.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                      <div className="d-flex align-items-start">
                        <div
                          className="nav flex-column nav-pills me-3 align-items-start"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <button
                            className="nav-link active"
                            id="v-pills-employee-database-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-employee-database"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-employee-database"
                            aria-selected="true"
                          >
                            Employee Database Management
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-self-service-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-self-service"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-self-service"
                            aria-selected="false"
                          >
                            Employee Self-Service Portal
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-attendance-tracking-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-attendance-tracking"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-attendance-tracking"
                            aria-selected="false"
                          >
                            Time and Attendance Tracking
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-applicant-tracking-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-applicant-tracking"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-applicant-tracking"
                            aria-selected="false"
                          >
                            Recruitment and Applicant Tracking
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-payroll-management-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-payroll-management"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-payroll-management"
                            aria-selected="false"
                          >
                            Payroll Management
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-leave-management-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-leave-management"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-leave-management"
                            aria-selected="false"
                          >
                            Leave Management
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-mobile-accessibility-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-mobile-accessibility"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-mobile-accessibility"
                            aria-selected="false"
                          >
                            Employee Training Schedules{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-6">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-employee-database"
                          role="tabpanel"
                          aria-labelledby="v-pills-employee-database-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div>
                                <img src={featuresImg1} alt="Features" />
                                <p>
                                  Keep your workforce data organized and secure
                                  with OpticalHR's robust employee database
                                  management system. Easily track employee
                                  profiles, contact details, employment history,
                                  and more, all in one place.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-self-service"
                          role="tabpanel"
                          aria-labelledby="v-pills-self-service-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div>
                                <img src={featuresImg2} alt="Features" />
                                <p>
                                  Empower your employees with a self-service
                                  portal that allows them to manage their
                                  personal information, view payslips, request
                                  leaves, and access company policies at their
                                  fingertips, enhancing transparency and
                                  convenience.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-attendance-tracking"
                          role="tabpanel"
                          aria-labelledby="v-pills-attendance-tracking-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div>
                                <img src={featuresImg3} alt="Features" />
                                <p>
                                  Streamline your timekeeping processes with
                                  OpticalHR's advanced tracking system. Automate
                                  time and attendance recording, reduce manual
                                  errors, and gain insights into workforce
                                  utilization and productivity.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-applicant-tracking"
                          role="tabpanel"
                          aria-labelledby="v-pills-applicant-tracking-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div>
                                <img src={featuresImg4} alt="Features" />
                                <p>
                                  Simplify your hiring process with OpticalHR's
                                  integrated recruitment module. Manage job
                                  postings, candidate applications, interviews,
                                  and onboarding all in one place, ensuring a
                                  smooth transition from recruitment to
                                  integration.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-payroll-management"
                          role="tabpanel"
                          aria-labelledby="v-pills-payroll-management-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div>
                                <img src={featuresImg5} alt="Features" />
                                <p>
                                  Automate and streamline your payroll processes
                                  with OpticalHR's payroll management feature.
                                  Ensure accuracy, compliance, and timely
                                  payments, freeing up HR to focus on strategic
                                  initiatives.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-leave-management"
                          role="tabpanel"
                          aria-labelledby="v-pills-leave-management-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div>
                                <img src={featuresImg6} alt="Features" />
                                <p>
                                  Manage leave requests and approvals
                                  efficiently with OpticalHR's leave management
                                  system. Set policies, automate notifications,
                                  and track leave balances, promoting fairness
                                  and consistency across the organization.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-mobile-accessibility"
                          role="tabpanel"
                          aria-labelledby="v-pills-mobile-accessibility-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div>
                                <img src={featuresImg7} alt="Features" />
                                <p>
                                  Optimize your training programs with
                                  OpticalHR's dedicated scheduling feature.
                                  Create, manage, and track training sessions
                                  effortlessly, ensuring that every employee
                                  receives the necessary support and development
                                  opportunities on time.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 mb-4">
                    <div className="col-lg-12">
                      <div className="text-center">
                        <a
                          className="btn book-demo-btn pt-0"
                          onClick={handleShow}
                        >
                          Book a Demo
                          <span className="material-symbols-rounded">
                            arrow_forward
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-4 mobile-screen-features-row">
            <div className="col-lg-12 text-center">
              <div className="card features-sec-card">
                <div className="card-body ps-0 pe-0">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <h2>Features</h2>
                      <p>
                        Cultivate Success with OpticalHR - Elevate Your HR
                        Management to New Heights. Our platform empowers
                        businesses with streamlined processes, enhanced
                        efficiency, and unparalleled insights. Experience cost
                        savings, compliance assurance, and increased employee
                        satisfaction.
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-12 text-center">
                      <div className="accordion" id="accordionExampleFeatures">
                        <div className="accordion-item mb-2">
                          <h2
                            className="accordion-header"
                            id="headingOneFeatures"
                          >
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Employee Database Management
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOneFeatures"
                            data-bs-parent="#accordionExampleFeatures"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <img src={featuresImg1} alt="Features" />
                                  <p>
                                    Keep your workforce data organized and
                                    secure with OpticalHR's robust employee
                                    database management system. Easily track
                                    employee profiles, contact details,
                                    employment history, and more, all in one
                                    place.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2
                            className="accordion-header"
                            id="headingTwoFeatures"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Employee Self-Service Portal
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwoFeatures"
                            data-bs-parent="#accordionExampleFeatures"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <img src={featuresImg2} alt="Features" />
                                  <p>
                                    Empower your employees with a self-service
                                    portal that allows them to manage their
                                    personal information, view payslips, request
                                    leaves, and access company policies at their
                                    fingertips, enhancing transparency and
                                    convenience.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2
                            className="accordion-header"
                            id="headingThreeFeatures"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Time and Attendance Tracking
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThreeFeatures"
                            data-bs-parent="#accordionExampleFeatures"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <img src={featuresImg3} alt="Features" />
                                  <p>
                                    Streamline your timekeeping processes with
                                    OpticalHR's advanced tracking system.
                                    Automate time and attendance recording,
                                    reduce manual errors, and gain insights into
                                    workforce utilization and productivity.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2
                            className="accordion-header"
                            id="headingFourFeatures"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Recruitment and Applicant Tracking
                            </button>
                          </h2>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFourFeatures"
                            data-bs-parent="#accordionExampleFeatures"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <img src={featuresImg4} alt="Features" />
                                  <p>
                                    Simplify your hiring process with
                                    OpticalHR's integrated recruitment module.
                                    Manage job postings, candidate applications,
                                    interviews, and onboarding all in one place,
                                    ensuring a smooth transition from
                                    recruitment to integration.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2
                            className="accordion-header"
                            id="headingFiveFeatures"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              Payroll Management
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFiveFeatures"
                            data-bs-parent="#accordionExampleFeatures"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <img src={featuresImg5} alt="Features" />
                                  <p>
                                    Automate and streamline your payroll
                                    processes with OpticalHR's payroll
                                    management feature. Ensure accuracy,
                                    compliance, and timely payments, freeing up
                                    HR to focus on strategic initiatives.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2
                            className="accordion-header"
                            id="headingSixFeatures"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Leave Management
                            </button>
                          </h2>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSixFeatures"
                            data-bs-parent="#accordionExampleFeatures"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <img src={featuresImg6} alt="Features" />
                                  <p>
                                    Manage leave requests and approvals
                                    efficiently with OpticalHR's leave
                                    management system. Set policies, automate
                                    notifications, and track leave balances,
                                    promoting fairness and consistency across
                                    the organization.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2
                            className="accordion-header"
                            id="headingSevenFeatures"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Employee Training Schedules
                            </button>
                          </h2>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSevenFeatures"
                            data-bs-parent="#accordionExampleFeatures"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <img src={featuresImg7} alt="Features" />
                                  <p>
                                    Optimize your training programs with
                                    OpticalHR's dedicated scheduling feature.
                                    Create, manage, and track training sessions
                                    effortlessly, ensuring that every employee
                                    receives the necessary support and
                                    development opportunities on time.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 mb-4">
                    <div className="col-lg-12">
                      <div className="text-center">
                        <a
                          className="btn book-demo-btn pt-0"
                          onClick={handleShow}
                        >
                          Book a Demo
                          <span className="material-symbols-rounded">
                            arrow_forward
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* hrRevolutionSec */}
      <div className="section" id="hrRevolutionSec">
        <div className="container px-4">
          <div className="row gx-4">
            <div className="col-lg-12 text-center">
              <div className="card hr-revolution-sec-card">
                <div className="card-body ps-0 pe-0">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <h2>
                        OpticalHR: Where the future of HR meets today's needs,
                        blending efficiency with empowerment to transform the
                        way we work and live
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* devicesWhereYouSec */}
      <div className="section" id="devicesWhereYouSec">
        <div className="container px-4">
          <div className="row gx-4">
            <div className="col-lg-5">
              <h2>
                Access OpticalHR<br></br> Anywhere, Anytime
              </h2>
              <p>
                OpticalHR understands this need and has been developed with
                flexibility and accessibility in mind. Whether you're on the go,
                working remotely, or managing your team from different
                locations, OpticalHR ensures you always have the tools you need
                at your fingertips.
              </p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6 text-center">
              <img src={devicesWhereYouImg} alt="Features" />
            </div>
          </div>
        </div>
      </div>
      {/* <!--chooseYourPlan-- > */}
      <section section id="chooseYourPlan">
        <div className="container px-4">
          <div className="row gx-4">
            <div className="col-lg-12 text-center">
              <div className="card choose-your-plan-sec-card">
                <div className="card-body ps-0 pe-0">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <h2>Choose Your Plans</h2>
                      <p>
                        At OpticalHR, we believe in empowering businesses of all
                        shapes and sizes to thrive in the modern workplace.
                        That's why we've crafted a selection of plans designed
                        to grow with you, offering the flexibility to scale your
                        HR solutions as your needs evolve.
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-4 text-center mb-2">
                      <div className="card">
                        <div className="card-body">
                          <h5>Essential Plan</h5>
                          <h6>1 to 25 Employees</h6>
                          <ul className="mt-4">
                            <li>
                              <span>Employee Management</span>
                            </li>
                            <li>
                              <span>Time Tracking</span>
                            </li>
                            <li>
                              <span>Leave Management</span>
                            </li>
                            <li>
                              <span>Basic Reporting</span>
                            </li>
                            <li>
                              <span>Employee Self-Service Portal</span>
                            </li>
                            <li>
                              <span>Mobile App Access</span>
                            </li>
                          </ul>
                        </div>
                        <div className="card-footer">
                          <a
                            className="btn book-demo-btn pt-0 mb-3"
                            onClick={handleShow}
                          >
                            Contact Sales
                            <span className="material-symbols-rounded">
                              arrow_forward
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 text-center mb-2">
                      <div className="card active-card">
                        <div className="card-body">
                          <h5>Growth Plan</h5>
                          <h6>26 to 100 Employees</h6>
                          <ul className="mt-4">
                            <li>
                              <span>All Essential Plan Features</span>
                            </li>
                            <li>
                              <span>Performance Management</span>
                            </li>
                            <li>
                              <span>Customizable Workflows</span>
                            </li>
                            <li>
                              <span>Advanced Reporting</span>
                            </li>
                            <li>
                              <span>Document Management</span>
                            </li>
                            <li>
                              <span>Compliance Tools</span>
                            </li>
                          </ul>
                        </div>
                        <div className="card-footer">
                          <a
                            className="btn contact-sales-btn pt-0 mb-3"
                            onClick={handleShow}
                          >
                            Contact Sales
                            <span className="material-symbols-rounded">
                              arrow_forward
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 text-center mb-2">
                      <div className="card">
                        <div className="card-body">
                          <h5>Premium Plan</h5>
                          <h6>More than 100 Employees</h6>
                          <ul className="mt-4">
                            <li>
                              <span>All Growth Plan Features</span>
                            </li>
                            <li>
                              <span>Recruitment Management</span>
                            </li>
                            <li>
                              <span>Training & Development</span>
                            </li>
                            <li>
                              <span>Dedicated Customer Support</span>
                            </li>
                            <li>
                              <span>Integration with Third-party Apps</span>
                            </li>
                            <li>
                              <span>Advanced Security Features</span>
                            </li>
                          </ul>
                        </div>
                        <div className="card-footer">
                          <a
                            className="btn book-demo-btn pt-0 mb-3"
                            onClick={handleShow}
                          >
                            Contact Sales
                            <span className="material-symbols-rounded">
                              arrow_forward
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--frequentlyAskedQuestionsSec-- > */}
      <div className="section" id="frequentlyAskedQuestionsSec">
        <div className="container px-4">
          <div className="row gx-4">
            <div className="col-lg-12 text-center">
              <div className="card frequently-asked-questions-sec-card">
                <div className="card-body ps-0 pe-0">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <h2>Frequently Asked Questions</h2>
                      <p>
                        Got questions? We've got answers Dive into our FAQ div
                        to learn more about OpticalHR's plans, pricing,
                        integration capabilities, and more.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-12 text-center">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item mb-2">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              What types of plans does OpticalHR offer?
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p>
                                    OpticalHR offers three main plans:
                                    Essentials, Growth, and Premier. Each plan
                                    is designed to cater to businesses of
                                    varying sizes and needs, starting from
                                    startups to large corporations. The
                                    Essentials Plan provides foundational HR
                                    functionalities, the Growth Plan adds depth
                                    with additional features, and the Premier
                                    Plan offers the most comprehensive set of
                                    tools and services.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              How much does OpticalHR cost?
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p>
                                    Pricing for OpticalHR varies depending on
                                    the plan chosen. Please visit our Pricing
                                    page for detailed information on the costs
                                    associated with each plan. We strive to
                                    offer competitive rates that reflect the
                                    value and quality of our services.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Can OpticalHR integrate with existing HR systems?
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p>
                                    Yes, OpticalHR is designed to be highly
                                    customizable and can integrate with many
                                    existing HR systems. However, the extent of
                                    integration may vary depending on the
                                    complexity of your current setup. Our team
                                    is experienced in handling integrations and
                                    can guide you through the process.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Is training included with OpticalHR?
                            </button>
                          </h2>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p>
                                    Training is an integral part of our service.
                                    Upon subscribing, you'll receive
                                    comprehensive training materials and access
                                    to webinars and workshops to help you and
                                    your team make the most out of OpticalHR.
                                    Additionally, our customer support team is
                                    available to assist with any questions or
                                    issues you may encounter.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2 className="accordion-header" id="headingFive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              How long does it take to implement OpticalHR?
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p>
                                    The implementation timeline can vary based
                                    on the complexity of your HR processes and
                                    the plan you choose. On average, it takes
                                    approximately two to four weeks to fully
                                    implement OpticalHR. Our team works closely
                                    with you throughout the process to ensure a
                                    smooth transition.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item mb-2">
                          <h2 className="accordion-header" id="headingSix">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              What kind of support does OpticalHR offer?
                            </button>
                          </h2>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p>
                                    OpticalHR is committed to providing
                                    top-notch customer support. Our team is
                                    available via phone, email, and chat to
                                    answer your questions and resolve any issues
                                    you may encounter. Additionally, we offer a
                                    knowledge base filled with articles and
                                    guides to help you navigate the platform.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* getInTouch */}
      <section id="getInTouch">
        <div className="container px-4">
          <ContactSales />
        </div>
      </section>
      {/* <!--Footer--> */}
      <footer id="footerSection">
        <div className="container px-4">
          <div className="row gx-4 pt-5 pb-5">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 mob-mb-25px">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="index.html">
                    <img src={logoImg} alt="Logo Image" className="img-fluid" />
                  </a>
                </div>
                <div className="footer-text">
                  <p>
                    Boost your HR department's efficiency with our OpticalHR
                    solution. Designed for optimal productivity, our cloud-based
                    platform offers superior value at competitive prices.
                  </p>
                  <p>
                    <span>Email :</span> sales@opticalhr.com
                  </p>
                  <p>
                    <span>Phone :</span>
                    +91 471 3592209 | +91 73068 89096
                  </p>
                  {/* <p>
                    Phone : +91 471 3592209 <br></br>&ensp; &emsp;&ensp; &emsp;
                    +91 73068 89096
                  </p> */}
                </div>
                <div className="footer-social-icon">
                  <a
                    href="https://www.facebook.com/people/Links4engg-India-PvtLtd/100064310673679/"
                    target="blank"
                  >
                    <img src={facebookImg} alt="Facebook" className="m-1" />
                  </a>
                  <a href="#">
                    <img src={instagramImg} alt="Instagram" className="m-1" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/links4engg/"
                    target="blank"
                  >
                    <img src={linkedinImg} alt="LinkedIn" className="m-1" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mob-mb-25px">
              <div className="footer-widget ps-4 mob-ps-0">
                <div className="footer-widget-heading">
                  <h3>Company</h3>
                </div>
                <ul>
                  <li>
                    <a href="#whyOpticalHr">Why OpticalHR</a>
                  </li>
                  <li>
                    <a href="#chooseYourPlan">Pricing</a>
                  </li>
                  <li>
                    <a href="#frequentlyAskedQuestionsSec">FAQs</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12 mob-mb-25px">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Features</h3>
                </div>
                <ul>
                  <li>
                    <a href="#">Employee Management</a>
                  </li>
                  <li>
                    <a href="#">Self-Service Portal</a>
                  </li>
                  <li>
                    <a href="#">Time and Attendance</a>
                  </li>
                  <li>
                    <a href="#">Recruitment and Applicant</a>
                  </li>
                  <li>
                    <a href="#">Payroll Management</a>
                  </li>
                  <li>
                    <a href="#">Leave Management</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mob-mb-25px">
              <div className="footer-widget ps-4 mob-ps-0">
                <div className="footer-widget-heading">
                  <h3>Legal</h3>
                </div>
                <ul>
                  <li>
                    <a href="#">Terms of Service</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Cookies Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 mob-mb-25px">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Download our App</h3>
                </div>
                <div className="footer-text mb-25">
                  <a href="#">
                    <img
                      src={playStoreImg}
                      alt="Play store"
                      className="mb-4 me-2"
                    />
                  </a>
                  <a href="#">
                    <img src={appStoreImg} alt="App store" className="mb-4" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container px-4">
            <div className="row gx-4">
              <div className="col-xl-6 col-lg-6 text-center text-lg-start my-auto">
                <div className="copyright-text">
                  <p>&copy; OPTICALHR. All rights reserved</p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 text-center text-lg-end my-auto">
                <div className="copyright-text">
                  <a href="https://www.links4engg.com/" target="blank">
                    A product of LINKS4ENGG
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* bookDemoModalToggle*/}
      <BookDemoModal
        handleClose={handleClose}
        show={show}
        time={time}
        formValue={formValue}
        setFormValue={setFormValue}
        setTime={setTime}
        setShow={setShow}
        setShowOtp={setShowOtp}
        handleShow={handleShow}
        startTimer={startTimer}
      />
      {/* verifyYourIdentityModalToggle*/}
      <VerifyYourIdentityModal
        responseData={responseData}
        setResponseData={setResponseData}
        startTimer={startTimer}
        formValue={formValue}
        showOtp={showOtp}
        show={show}
        setShow={setShow}
        time={time}
        setTime={setTime}
        setShowOtp={setShowOtp}
        handleOtpShow={handleOtpShow}
        handleOtpClose={handleOtpClose}
      />
    </>
  );
}

export default App;
