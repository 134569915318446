export const validateForm = (formValue, generatedCaptcha, fieldToValidate) => {
  const errors = {};

  if (!fieldToValidate || fieldToValidate === "name") {
    if (!formValue.name) {
      errors.name = "Name is required";
    }
  }

  if (!fieldToValidate || fieldToValidate === "email") {
    if (!formValue.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.email)
    ) {
      errors.email = "Invalid email address";
    }
  }

  if (!fieldToValidate || fieldToValidate === "phoneNumber") {
    if (!formValue.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(formValue.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number";
    }
  }

  if (!fieldToValidate || fieldToValidate === "noOfEmployees") {
    if (!formValue.noOfEmployees) {
      errors.noOfEmployees = "Number of employees is required";
    }
  }

  if (!fieldToValidate || fieldToValidate === "requirements") {
    if (!formValue.requirements) {
      errors.requirements = "Requirements is required";
    }
  }

  if (!fieldToValidate || fieldToValidate === "country") {
    if (!formValue.country) {
      errors.country = "Country is required";
    }
  }

  if (!fieldToValidate || fieldToValidate === "companyName") {
    if (!formValue.companyName) {
      errors.companyName = "Company Name is required";
    }
  }

  if (!fieldToValidate || fieldToValidate === "captcha") {
    if (!formValue.captcha) {
      errors.captcha = "Captcha is required";
    } else if (formValue.captcha !== generatedCaptcha) {
      errors.captcha = "Captcha does not match";
    }
  }

  return errors;
};
