/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($pk: String!, $id: String!) {
    getCompany(pk: $pk, id: $id) {
      pk
      id
      companyName
      subscriptionID
      subdomain
      subscriptionDetails {
        plan
        amount
        subscriptionPeriod
        currency
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      employeeCount
      searchKey
      companyLogo
      telephone
      email
      fax
      website
      status
      createdBy
      legalName
      displayName
      registrationNumber
      tin
      companyStrength
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        companyName
        subscriptionID
        subdomain
        employeeCount
        searchKey
        companyLogo
        telephone
        email
        fax
        website
        status
        createdBy
        legalName
        displayName
        registrationNumber
        tin
        companyStrength
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SortByCompanyDate = /* GraphQL */ `
  query SortByCompanyDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SortByCompanyDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        companyName
        subscriptionID
        subdomain
        employeeCount
        searchKey
        companyLogo
        telephone
        email
        fax
        website
        status
        createdBy
        legalName
        displayName
        registrationNumber
        tin
        companyStrength
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($pk: String!, $id: String!) {
    getDepartment(pk: $pk, id: $id) {
      pk
      id
      departmentName
      description
      noOfEmployee
      searchKey
      lead
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDepartments(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        departmentName
        description
        noOfEmployee
        searchKey
        lead
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const departmentByStatus = /* GraphQL */ `
  query DepartmentByStatus(
    $pk: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentByStatus(
      pk: $pk
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        departmentName
        description
        noOfEmployee
        searchKey
        lead
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDesignation = /* GraphQL */ `
  query GetDesignation($pk: String!, $id: String!) {
    getDesignation(pk: $pk, id: $id) {
      pk
      id
      title
      level
      description
      noOfEmployee
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDesignations = /* GraphQL */ `
  query ListDesignations(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelDesignationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDesignations(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        title
        level
        description
        noOfEmployee
        searchKey
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const designationByStatus = /* GraphQL */ `
  query DesignationByStatus(
    $pk: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDesignationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    designationByStatus(
      pk: $pk
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        title
        level
        description
        noOfEmployee
        searchKey
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGrade = /* GraphQL */ `
  query GetGrade($pk: String!, $id: String!) {
    getGrade(pk: $pk, id: $id) {
      pk
      id
      title
      designation
      CTC
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGrades = /* GraphQL */ `
  query ListGrades(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelGradeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGrades(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        title
        designation
        CTC
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaveMaster = /* GraphQL */ `
  query GetLeaveMaster($pk: String!, $id: String!) {
    getLeaveMaster(pk: $pk, id: $id) {
      pk
      id
      leaveTitle
      shortCode
      noOfDays
      searchKey
      applicable
      transferable
      cashable
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLeaveMasters = /* GraphQL */ `
  query ListLeaveMasters(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelLeaveMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLeaveMasters(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        leaveTitle
        shortCode
        noOfDays
        searchKey
        applicable
        transferable
        cashable
        description
        createdBy
        updatedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const leaveByDate = /* GraphQL */ `
  query LeaveByDate(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelleaveMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leaveByDate(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        leaveTitle
        shortCode
        noOfDays
        searchKey
        applicable
        transferable
        cashable
        description
        createdBy
        updatedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($pk: String!, $id: String!) {
    getEmployee(pk: $pk, id: $id) {
      pk
      id
      userID
      employeeName
      empImage
      empImageName
      companyId
      companyName
      designationId
      designationName
      departmentId
      departmentName
      DOJ
      officialEmail
      reportingTo
      overtimeEligible
      otherDetails {
        aadharNumber
        aadharDocName
        aadharDocUrl
        panNumber
        panDocName
        panDocUrl
        passportNumber
        passportDocName
        passportDocUrl
        drivingLicence
        licenceExpiry
        insuranceNumber
        renewalPeriod
        verificationDoneBy
        verificationDate
        reportUrl
        reportDocName
        __typename
      }
      fresher
      probationCompleted
      probationCompletedOn
      probation
      experience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      education {
        courseTitle
        subject
        institute
        college
        durationFrom
        durationTo
        educationType
        documentUrl
        documentName
        courseType
        percentage
        __typename
      }
      status
      bloodGroup
      dob
      gender
      nationality
      personalEmail
      mobNumber
      primaryContactName
      primaryContactRelation
      primaryContactNumber
      secondaryContactName
      secondaryContactRelation
      secondaryContactNumber
      familyStatus
      marriedStatus
      language {
        languageName
        read
        write
        speak
        native
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      selfService
      mandatoryPasswordReset
      shift
      searchKey
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      employeePF
      employeeESI
      employeeTDS
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployees(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        userID
        employeeName
        empImage
        empImageName
        companyId
        companyName
        designationId
        designationName
        departmentId
        departmentName
        DOJ
        officialEmail
        reportingTo
        overtimeEligible
        fresher
        probationCompleted
        probationCompletedOn
        probation
        status
        bloodGroup
        dob
        gender
        nationality
        personalEmail
        mobNumber
        primaryContactName
        primaryContactRelation
        primaryContactNumber
        secondaryContactName
        secondaryContactRelation
        secondaryContactNumber
        familyStatus
        marriedStatus
        selfService
        mandatoryPasswordReset
        shift
        searchKey
        employeePF
        employeeESI
        employeeTDS
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SortByEmployeeStatus = /* GraphQL */ `
  query SortByEmployeeStatus(
    $pk: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SortByEmployeeStatus(
      pk: $pk
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        userID
        employeeName
        empImage
        empImageName
        companyId
        companyName
        designationId
        designationName
        departmentId
        departmentName
        DOJ
        officialEmail
        reportingTo
        overtimeEligible
        fresher
        probationCompleted
        probationCompletedOn
        probation
        status
        bloodGroup
        dob
        gender
        nationality
        personalEmail
        mobNumber
        primaryContactName
        primaryContactRelation
        primaryContactNumber
        secondaryContactName
        secondaryContactRelation
        secondaryContactNumber
        familyStatus
        marriedStatus
        selfService
        mandatoryPasswordReset
        shift
        searchKey
        employeePF
        employeeESI
        employeeTDS
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoleManagement = /* GraphQL */ `
  query GetRoleManagement($pk: String!, $id: String!) {
    getRoleManagement(pk: $pk, id: $id) {
      pk
      id
      RoleName
      moduleHrms {
        moduleName
        fullAccess
        readOnly
        create
        delete
        __typename
      }
      moduleRecruitment {
        moduleName
        fullAccess
        readOnly
        create
        delete
        __typename
      }
      searchKey
      status
      createdBy
      updated_by
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRoleManagements = /* GraphQL */ `
  query ListRoleManagements(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelRoleManagementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoleManagements(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        RoleName
        searchKey
        status
        createdBy
        updated_by
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roleByStatus = /* GraphQL */ `
  query RoleByStatus(
    $pk: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelroleManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleByStatus(
      pk: $pk
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        RoleName
        searchKey
        status
        createdBy
        updated_by
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShift = /* GraphQL */ `
  query GetShift($pk: String!, $id: String!) {
    getShift(pk: $pk, id: $id) {
      pk
      id
      shiftCode
      name
      startTime
      endTime
      weeklyDays
      status
      description
      searchKey
      secondSaturday
      alternateSaturdays
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listShifts = /* GraphQL */ `
  query ListShifts(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShifts(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        shiftCode
        name
        startTime
        endTime
        weeklyDays
        status
        description
        searchKey
        secondSaturday
        alternateSaturdays
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shiftByStatus = /* GraphQL */ `
  query ShiftByStatus(
    $pk: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shiftByStatus(
      pk: $pk
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        shiftCode
        name
        startTime
        endTime
        weeklyDays
        status
        description
        searchKey
        secondSaturday
        alternateSaturdays
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeAppraisal = /* GraphQL */ `
  query GetEmployeeAppraisal($pk: String!, $id: String!) {
    getEmployeeAppraisal(pk: $pk, id: $id) {
      pk
      id
      sk
      appraisalType
      promotedTo
      departmentId
      departmentName
      promotionDate
      appraisalScore
      comments
      reviews
      attachmentUrl
      attachmentName
      effectivefrom
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      previousDetails {
        designation
        reviews
        appraisalScore
        totalEarnings
        totalDeduction
        __typename
      }
      confirmationLetterpdf
      appraisalLetterpd
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployeeAppraisals = /* GraphQL */ `
  query ListEmployeeAppraisals(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmployeeAppraisalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeAppraisals(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        appraisalType
        promotedTo
        departmentId
        departmentName
        promotionDate
        appraisalScore
        comments
        reviews
        attachmentUrl
        attachmentName
        effectivefrom
        totalEarnings
        totalDeduction
        confirmationLetterpdf
        appraisalLetterpd
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeDocument = /* GraphQL */ `
  query GetEmployeeDocument($pk: String!, $id: String!) {
    getEmployeeDocument(pk: $pk, id: $id) {
      pk
      id
      sk
      documentType
      expiry
      documentUrl
      docName
      visibleToEmployee
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployeeDocuments = /* GraphQL */ `
  query ListEmployeeDocuments(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmployeeDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeDocuments(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        documentType
        expiry
        documentUrl
        docName
        visibleToEmployee
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeFamily = /* GraphQL */ `
  query GetEmployeeFamily($pk: String!, $id: String!) {
    getEmployeeFamily(pk: $pk, id: $id) {
      pk
      id
      sk
      name
      relationShip
      documentType
      expiryDate
      attachmentUrl
      attachmentName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployeeFamilies = /* GraphQL */ `
  query ListEmployeeFamilies(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmployeeFamilyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeFamilies(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        name
        relationShip
        documentType
        expiryDate
        attachmentUrl
        attachmentName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeSeperation = /* GraphQL */ `
  query GetEmployeeSeperation($pk: String!, $id: String!) {
    getEmployeeSeperation(pk: $pk, id: $id) {
      pk
      id
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      seperationReason
      handoverTo
      issuedDate
      releavingDate
      reason
      resignationLetter
      otherEarningsReason
      otherDeductionsReason
      finalSettlementLetterPDF
      attachmentUrl
      releavingLetterPDF
      experienceLetterPDF
      finalSettlementLetter
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployeeSeperations = /* GraphQL */ `
  query ListEmployeeSeperations(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmployeeSeperationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeSeperations(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        totalEarnings
        totalDeduction
        seperationReason
        handoverTo
        issuedDate
        releavingDate
        reason
        resignationLetter
        otherEarningsReason
        otherDeductionsReason
        finalSettlementLetterPDF
        attachmentUrl
        releavingLetterPDF
        experienceLetterPDF
        finalSettlementLetter
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeLoan = /* GraphQL */ `
  query GetEmployeeLoan($pk: String!, $id: String!) {
    getEmployeeLoan(pk: $pk, id: $id) {
      pk
      id
      sk
      loanAmount
      approvedAmount
      noOfInstalment
      employeeName
      empImage
      extendedDuration
      paymentStartdate
      paymentEnddate
      status
      instalmentDetails {
        paymentDate
        amount
        paymentStatus
        __typename
      }
      instalmentAmount
      type
      subject
      description
      document
      reportingTo
      approvalDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployeeLoans = /* GraphQL */ `
  query ListEmployeeLoans(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmployeeLoanFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeLoans(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        loanAmount
        approvedAmount
        noOfInstalment
        employeeName
        empImage
        extendedDuration
        paymentStartdate
        paymentEnddate
        status
        instalmentAmount
        type
        subject
        description
        document
        reportingTo
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeSalary = /* GraphQL */ `
  query GetEmployeeSalary($pk: String!, $id: String!) {
    getEmployeeSalary(pk: $pk, id: $id) {
      pk
      id
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployeeSalaries = /* GraphQL */ `
  query ListEmployeeSalaries(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmployeeSalaryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeSalaries(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        totalEarnings
        totalDeduction
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCountryDetails = /* GraphQL */ `
  query GetCountryDetails($pk: String!, $id: String!) {
    getCountryDetails(pk: $pk, id: $id) {
      pk
      id
      country
      state
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCountryDetails = /* GraphQL */ `
  query ListCountryDetails(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelCountryDetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCountryDetails(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        country
        state
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDailyAttendance = /* GraphQL */ `
  query GetDailyAttendance($pk: String!, $id: ID!) {
    getDailyAttendance(pk: $pk, id: $id) {
      pk
      sk
      id
      Type
      loginType
      inLocation
      outLocation
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      currentdate
      shift
      shiftFromTime
      shiftToTime
      intime
      outtime
      islate
      isearly
      break {
        in
        out
        __typename
      }
      overtime
      workingHours
      overtimeEligible
      totalDuration
      latitude
      longitude
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDailyAttendances = /* GraphQL */ `
  query ListDailyAttendances(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelDailyAttendanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDailyAttendances(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        id
        Type
        loginType
        inLocation
        outLocation
        employeeName
        employeeImage
        designationID
        designationName
        departmentID
        departmentName
        currentdate
        shift
        shiftFromTime
        shiftToTime
        intime
        outtime
        islate
        isearly
        overtime
        workingHours
        overtimeEligible
        totalDuration
        latitude
        longitude
        searchKey
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayrollHeads = /* GraphQL */ `
  query GetPayrollHeads($pk: String!, $id: String!) {
    getPayrollHeads(pk: $pk, id: $id) {
      pk
      id
      headName
      category
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPayrollHeads = /* GraphQL */ `
  query ListPayrollHeads(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelPayrollHeadsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPayrollHeads(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        headName
        category
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeeklyAttendance = /* GraphQL */ `
  query GetWeeklyAttendance($pk: String!, $id: ID!) {
    getWeeklyAttendance(pk: $pk, id: $id) {
      pk
      sk
      id
      Type
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      month
      year
      week
      totalworkingdays
      fromdate
      todate
      overtimeEligible
      attendanceDetails {
        currentdate
        timein
        timeout
        status
        overtime
        islate
        isearly
        __typename
      }
      totalPresent
      totalAbsent
      totalLeaves
      totalDuration
      totalOvertime
      totalLateby
      totalEarnedLeaves
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWeeklyAttendances = /* GraphQL */ `
  query ListWeeklyAttendances(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelWeeklyAttendanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWeeklyAttendances(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        id
        Type
        employeeName
        employeeImage
        designationID
        designationName
        departmentID
        departmentName
        month
        year
        week
        totalworkingdays
        fromdate
        todate
        overtimeEligible
        totalPresent
        totalAbsent
        totalLeaves
        totalDuration
        totalOvertime
        totalLateby
        totalEarnedLeaves
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMonthlyAttendance = /* GraphQL */ `
  query GetMonthlyAttendance($pk: String!, $id: ID!) {
    getMonthlyAttendance(pk: $pk, id: $id) {
      pk
      sk
      id
      Type
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      month
      year
      totalworkingdays
      fromdate
      todate
      overtimeEligible
      attendanceDetails {
        currentdate
        timein
        timeout
        status
        overtime
        islate
        isearly
        __typename
      }
      totalPresent
      totalAbsent
      totalLeaves
      totalDuration
      totalOvertime
      totalLateby
      totalEarnedLeaves
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMonthlyAttendances = /* GraphQL */ `
  query ListMonthlyAttendances(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelMonthlyAttendanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMonthlyAttendances(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        id
        Type
        employeeName
        employeeImage
        designationID
        designationName
        departmentID
        departmentName
        month
        year
        totalworkingdays
        fromdate
        todate
        overtimeEligible
        totalPresent
        totalAbsent
        totalLeaves
        totalDuration
        totalOvertime
        totalLateby
        totalEarnedLeaves
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($pk: String!, $id: ID!) {
    getNotifications(pk: $pk, id: $id) {
      pk
      sk
      notificationType
      id
      Title
      Message
      employeeID
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        notificationType
        id
        Title
        Message
        employeeID
        employeeName
        employeeImage
        designationID
        designationName
        departmentID
        departmentName
        DateTime
        notificationStatus
        notificationModule
        referenceID
        targetURL
        searchKey
        expiryDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMailLog = /* GraphQL */ `
  query GetMailLog($pk: String!, $id: ID!) {
    getMailLog(pk: $pk, id: $id) {
      pk
      sk
      id
      emailTemplateReference
      referenceID
      userID
      title
      emailTemplate
      mailDetails {
        sentTo
        sentDate
        sentBy
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMailLogs = /* GraphQL */ `
  query ListMailLogs(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelMailLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMailLogs(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        id
        emailTemplateReference
        referenceID
        userID
        title
        emailTemplate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHolidayList = /* GraphQL */ `
  query GetHolidayList($pk: String!, $id: ID!) {
    getHolidayList(pk: $pk, id: $id) {
      pk
      sk
      id
      year
      date
      day
      title
      holidayType
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listHolidayLists = /* GraphQL */ `
  query ListHolidayLists(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelHolidayListFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHolidayLists(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        id
        year
        date
        day
        title
        holidayType
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAvailableLeaves = /* GraphQL */ `
  query GetAvailableLeaves($pk: String!, $id: ID!) {
    getAvailableLeaves(pk: $pk, id: $id) {
      pk
      id
      sk
      companyID
      employeeID
      employeeName
      year
      department
      DOJ
      availableLeaves {
        leaveType
        totalAvailable
        totalTaken
        remaining
        isCarryforward
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAvailableLeaves = /* GraphQL */ `
  query ListAvailableLeaves(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelAvailableLeavesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAvailableLeaves(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        companyID
        employeeID
        employeeName
        year
        department
        DOJ
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const SortByLeaveYear = /* GraphQL */ `
  query SortByLeaveYear(
    $pk: String!
    $year: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableLeavesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SortByLeaveYear(
      pk: $pk
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        sk
        companyID
        employeeID
        employeeName
        year
        department
        DOJ
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaveRequest = /* GraphQL */ `
  query GetLeaveRequest($pk: String!, $id: ID!) {
    getLeaveRequest(pk: $pk, id: $id) {
      pk
      id
      sk
      companyID
      employeeID
      employeeName
      empImage
      reference
      year
      isPaid
      halfDay
      fullDay
      designation
      leaveType
      leaveFrom
      leaveTo
      totalDays
      documentName
      documentUrl
      reasonForLeave
      reasonForReject
      status
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLeaveRequests = /* GraphQL */ `
  query ListLeaveRequests(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelLeaveRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLeaveRequests(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        companyID
        employeeID
        employeeName
        empImage
        reference
        year
        isPaid
        halfDay
        fullDay
        designation
        leaveType
        leaveFrom
        leaveTo
        totalDays
        documentName
        documentUrl
        reasonForLeave
        reasonForReject
        status
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const leaveRequestByYear = /* GraphQL */ `
  query LeaveRequestByYear(
    $pk: String!
    $year: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaveRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leaveRequestByYear(
      pk: $pk
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        sk
        companyID
        employeeID
        employeeName
        empImage
        reference
        year
        isPaid
        halfDay
        fullDay
        designation
        leaveType
        leaveFrom
        leaveTo
        totalDays
        documentName
        documentUrl
        reasonForLeave
        reasonForReject
        status
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayRoll = /* GraphQL */ `
  query GetPayRoll($pk: String!, $id: ID!) {
    getPayRoll(pk: $pk, id: $id) {
      pk
      id
      month
      year
      department
      designation
      employeeID
      employeeName
      employeeESI
      employeePF
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      earnings {
        earningType
        earningAmount
        __typename
      }
      presentdays
      totalDeduction
      totalEarnings
      totalamount
      totalduration
      otherEarnings
      otherDeductions
      createdBy
      workingdays
      searchKey
      status
      overtimeamount
      leavedeductionamount
      loanamount
      paySlipUrl
      TDS
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPayRolls = /* GraphQL */ `
  query ListPayRolls(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelPayRollFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPayRolls(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        month
        year
        department
        designation
        employeeID
        employeeName
        employeeESI
        employeePF
        presentdays
        totalDeduction
        totalEarnings
        totalamount
        totalduration
        otherEarnings
        otherDeductions
        createdBy
        workingdays
        searchKey
        status
        overtimeamount
        leavedeductionamount
        loanamount
        paySlipUrl
        TDS
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrainingSchedule = /* GraphQL */ `
  query GetTrainingSchedule($pk: String!, $id: ID!) {
    getTrainingSchedule(pk: $pk, id: $id) {
      pk
      id
      trainingName
      trainingProvidedBy
      designation
      medium
      startDate
      endDate
      startTime
      endTime
      location
      mode
      attendee
      attended
      attendedBy
      documentName
      documentUrl
      description
      searchKey
      status
      createdBy
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTrainingSchedules = /* GraphQL */ `
  query ListTrainingSchedules(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelTrainingScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrainingSchedules(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        trainingName
        trainingProvidedBy
        designation
        medium
        startDate
        endDate
        startTime
        endTime
        location
        mode
        attendee
        attended
        attendedBy
        documentName
        documentUrl
        description
        searchKey
        status
        createdBy
        modifiedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts($pk: String!, $id: ID!) {
    getContacts(pk: $pk, id: $id) {
      pk
      id
      sk
      sectionName
      searchKey
      employeeID
      employeeName
      empImage
      gender
      designation
      contactNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContacts(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        sectionName
        searchKey
        employeeID
        employeeName
        empImage
        gender
        designation
        contactNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($pk: String!, $id: ID!) {
    getContent(pk: $pk, id: $id) {
      pk
      id
      sk
      sectionName
      searchKey
      content
      docUrl
      visibleToEmployee
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContents(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        sectionName
        searchKey
        content
        docUrl
        visibleToEmployee
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($pk: String!, $id: ID!) {
    getNews(pk: $pk, id: $id) {
      pk
      id
      sk
      postedOn
      searchKey
      title
      banner
      docUrl
      docName
      content
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNews(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        postedOn
        searchKey
        title
        banner
        docUrl
        docName
        content
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDropdown = /* GraphQL */ `
  query GetDropdown($pk: String!, $id: ID!) {
    getDropdown(pk: $pk, id: $id) {
      pk
      id
      title
      dropdownType
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDropdowns = /* GraphQL */ `
  query ListDropdowns(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelDropdownFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDropdowns(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        title
        dropdownType
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserRole = /* GraphQL */ `
  query GetUserRole($pk: String!, $id: String!) {
    getUserRole(pk: $pk, id: $id) {
      pk
      id
      name
      email
      roleName
      roleID
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelUserRoleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserRoles(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        name
        email
        roleName
        roleID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApprovalMaster = /* GraphQL */ `
  query GetApprovalMaster($pk: String!, $id: ID!) {
    getApprovalMaster(pk: $pk, id: $id) {
      pk
      id
      section
      type
      searchKey
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listApprovalMasters = /* GraphQL */ `
  query ListApprovalMasters(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelApprovalMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApprovalMasters(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        section
        type
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneralRequest = /* GraphQL */ `
  query GetGeneralRequest($pk: String!, $id: ID!) {
    getGeneralRequest(pk: $pk, id: $id) {
      pk
      id
      sk
      employeeID
      employeeName
      empImage
      reference
      designation
      type
      subject
      requestedOn
      documentList {
        docName
        docUrl
        __typename
      }
      documentName
      documentUrl
      status
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      searchKey
      description
      doj
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGeneralRequests = /* GraphQL */ `
  query ListGeneralRequests(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelGeneralRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGeneralRequests(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        employeeID
        employeeName
        empImage
        reference
        designation
        type
        subject
        requestedOn
        documentName
        documentUrl
        status
        searchKey
        description
        doj
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTDSMaster = /* GraphQL */ `
  query GetTDSMaster($pk: String!, $id: String!) {
    getTDSMaster(pk: $pk, id: $id) {
      pk
      id
      tdsName
      description
      searchKey
      exemptionsType
      exemptionLimit
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTDSMasters = /* GraphQL */ `
  query ListTDSMasters(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelTDSMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTDSMasters(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        tdsName
        description
        searchKey
        exemptionsType
        exemptionLimit
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmpTDSReference = /* GraphQL */ `
  query GetEmpTDSReference($pk: String!, $id: ID!) {
    getEmpTDSReference(pk: $pk, id: $id) {
      pk
      id
      sk
      year_month
      tdsName
      slabDetails {
        slabType
        limit
        __typename
      }
      exemption {
        exemptionsType
        exemptionAmount
        __typename
      }
      totalExemption
      standardDeduction
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmpTDSReferences = /* GraphQL */ `
  query ListEmpTDSReferences(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelEmpTDSReferenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmpTDSReferences(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        year_month
        tdsName
        totalExemption
        standardDeduction
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sortByEmployeeTDS = /* GraphQL */ `
  query SortByEmployeeTDS(
    $pk: String!
    $skYear_month: ModelEmpTDSReferenceEmployeeTDSIndexCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmpTDSReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sortByEmployeeTDS(
      pk: $pk
      skYear_month: $skYear_month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        sk
        year_month
        tdsName
        totalExemption
        standardDeduction
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptionPlan = /* GraphQL */ `
  query GetSubscriptionPlan($pk: String!, $id: String!) {
    getSubscriptionPlan(pk: $pk, id: $id) {
      pk
      id
      subscriptionName
      subscriptionType
      validity
      section
      amount
      noOfUsers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSubscriptionPlans = /* GraphQL */ `
  query ListSubscriptionPlans(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelSubscriptionPlanFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubscriptionPlans(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        subscriptionName
        subscriptionType
        validity
        section
        amount
        noOfUsers
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptions = /* GraphQL */ `
  query GetSubscriptions($pk: String!, $id: String!) {
    getSubscriptions(pk: $pk, id: $id) {
      pk
      id
      subscriptionPlanID
      customerName
      companyName
      renewal
      emailID
      contactNumber
      companyStrength
      companyWebsite
      plan
      companyID
      parentCompanyID
      parentCompanyName
      lastBillingDate
      nextBillingDate
      billingHistory {
        paymentDate
        validFrom
        validTo
        paymentMethod
        amount
        paymentStatus
        __typename
      }
      accountStatus
      referedBy
      subdomain
      paymentDetails {
        paymentType
        upiID
        transactionID
        accountNumber
        bankName
        branch
        ifsc
        cardType
        cardHolder
        cardNumber
        expiry
        cvv
        paymentAmount
        paidOn
        __typename
      }
      billingInfo {
        billingName
        billingAddress
        city
        state
        zipcode
        country
        emailAddress
        phone
        Vat
        __typename
      }
      subscriptionType
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSubscriptions = /* GraphQL */ `
  query ListSubscriptions(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubscriptions(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        subscriptionPlanID
        customerName
        companyName
        renewal
        emailID
        contactNumber
        companyStrength
        companyWebsite
        plan
        companyID
        parentCompanyID
        parentCompanyName
        lastBillingDate
        nextBillingDate
        accountStatus
        referedBy
        subdomain
        subscriptionType
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeExtension = /* GraphQL */ `
  query GetEmployeeExtension($pk: String!, $id: String!) {
    getEmployeeExtension(pk: $pk, id: $id) {
      pk
      id
      bankDetails {
        accountHolder
        bankName
        accountNumber
        branch
        ifscCode
        esiNumber
        pfNumber
        UAN
        __typename
      }
      shiftDetails {
        shiftType
        fromDate
        toDate
        assignedBy
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployeeExtensions = /* GraphQL */ `
  query ListEmployeeExtensions(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmployeeExtensionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeExtensions(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneralSettings = /* GraphQL */ `
  query GetGeneralSettings($pk: String!, $id: String!) {
    getGeneralSettings(pk: $pk, id: $id) {
      pk
      id
      companyID
      employeePF
      employeeESI
      TDS
      overtimeRate
      normalOvertimeRenumeration
      normalOvertimeRenumerationRate
      holidayOvertimeRenumeration
      holidayOvertimeRenumerationRate
      calendarBased
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGeneralSettings = /* GraphQL */ `
  query ListGeneralSettings(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelGeneralSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGeneralSettings(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        companyID
        employeePF
        employeeESI
        TDS
        overtimeRate
        normalOvertimeRenumeration
        normalOvertimeRenumerationRate
        holidayOvertimeRenumeration
        holidayOvertimeRenumerationRate
        calendarBased
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneralInterviewProcess = /* GraphQL */ `
  query GetGeneralInterviewProcess($pk: String!, $id: String!) {
    getGeneralInterviewProcess(pk: $pk, id: $id) {
      pk
      id
      interviewProcess
      interviewType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGeneralInterviewProcesses = /* GraphQL */ `
  query ListGeneralInterviewProcesses(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelGeneralInterviewProcessFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGeneralInterviewProcesses(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        interviewProcess
        interviewType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneralAgency = /* GraphQL */ `
  query GetGeneralAgency($pk: String!, $id: String!) {
    getGeneralAgency(pk: $pk, id: $id) {
      pk
      id
      agencyName
      contact
      email
      phone
      website
      address
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGeneralAgencies = /* GraphQL */ `
  query ListGeneralAgencies(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelGeneralAgencyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGeneralAgencies(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        agencyName
        contact
        email
        phone
        website
        address
        otp
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatures = /* GraphQL */ `
  query GetFeatures($pk: String!, $id: String!) {
    getFeatures(pk: $pk, id: $id) {
      pk
      id
      mainFeature
      subFeatures {
        featureName
        enable
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelFeaturesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeatures(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        mainFeature
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyDocuments = /* GraphQL */ `
  query GetCompanyDocuments($pk: String!, $id: String!) {
    getCompanyDocuments(pk: $pk, id: $id) {
      pk
      id
      docTypeName
      registrationNumber
      expiryDate
      docUrl
      docName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanyDocuments = /* GraphQL */ `
  query ListCompanyDocuments(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelCompanyDocumentsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyDocuments(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        docTypeName
        registrationNumber
        expiryDate
        docUrl
        docName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBackGroundVerification = /* GraphQL */ `
  query GetBackGroundVerification($pk: String!, $id: String!) {
    getBackGroundVerification(pk: $pk, id: $id) {
      pk
      id
      sk
      applicantEmail
      applicantPhone
      agencyName
      verificationProcess {
        verficationName
        checkVerification
        __typename
      }
      document {
        docUrl
        docName
        docStatus
        reasonForFailure
        __typename
      }
      remarks
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBackGroundVerifications = /* GraphQL */ `
  query ListBackGroundVerifications(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelBackGroundVerificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBackGroundVerifications(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        applicantEmail
        applicantPhone
        agencyName
        remarks
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobPost = /* GraphQL */ `
  query GetJobPost($pk: String!, $id: String!) {
    getJobPost(pk: $pk, id: $id) {
      pk
      id
      requesterId
      requesterName
      companyName
      department
      jobId
      jobTitle
      designation
      jobType
      jobDescription
      responsibilities
      skills
      grade
      startDate
      closingDate
      yearsOfExperience
      jobLocation
      referralSite
      noOfVacancies
      workType
      minSalary
      maxSalary
      trainingCost
      otp
      requestStatus
      medium
      openPosition
      applicants
      shortlist
      onProcess
      rejected
      hired
      interviewProcess {
        level
        roundName
        interviewerID
        interviewerName
        interviewerRole
        interviewType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJobPosts = /* GraphQL */ `
  query ListJobPosts(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelJobPostFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobPosts(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        requesterId
        requesterName
        companyName
        department
        jobId
        jobTitle
        designation
        jobType
        jobDescription
        responsibilities
        skills
        grade
        startDate
        closingDate
        yearsOfExperience
        jobLocation
        referralSite
        noOfVacancies
        workType
        minSalary
        maxSalary
        trainingCost
        otp
        requestStatus
        medium
        openPosition
        applicants
        shortlist
        onProcess
        rejected
        hired
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailTemplates = /* GraphQL */ `
  query GetEmailTemplates($pk: String!, $id: String!) {
    getEmailTemplates(pk: $pk, id: $id) {
      pk
      id
      templateTitle
      emailContent
      subject
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmailTemplates = /* GraphQL */ `
  query ListEmailTemplates(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmailTemplatesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmailTemplates(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        templateTitle
        emailContent
        subject
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResumeBank = /* GraphQL */ `
  query GetResumeBank($pk: String!, $id: String!) {
    getResumeBank(pk: $pk, id: $id) {
      pk
      id
      name
      email
      phone
      qualification
      jobTitle
      keywords
      searchKey
      resumeName
      resumeUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listResumeBanks = /* GraphQL */ `
  query ListResumeBanks(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelResumeBankFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResumeBanks(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        name
        email
        phone
        qualification
        jobTitle
        keywords
        searchKey
        resumeName
        resumeUrl
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicants = /* GraphQL */ `
  query GetApplicants($pk: String!, $id: String!) {
    getApplicants(pk: $pk, id: $id) {
      pk
      id
      sk
      jobID
      designation
      aadharNo
      department
      reasonForReject
      candidateName
      experience
      grade
      email
      phone
      resumeName
      resumeUrl
      probation
      jobMatch
      aboutEmployee
      referralName
      referralID
      referralDepartment
      referralDesignation
      history {
        status
        approvedBy
        approvedOn
        __typename
      }
      applicationStatus
      currentCtc
      expectedCtc
      currentLocation
      description
      fresher
      workExperience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      skills
      location
      screeningDetails {
        screeningType
        interviewType
        date
        time
        duration
        eventTitle
        conductedBy
        institution
        medium
        notes
        documentName
        documentUrl
        subject
        message
        agency
        password
        cc
        emailContent
        reasonForOverride
        verificationProcess
        screeningStatus
        updatedAt
        __typename
      }
      interviewProcess {
        level
        roundName
        interviewerID
        interviewerName
        interviewerRole
        interviewType
        __typename
      }
      previousDetails {
        jobTitle
        reasonForReject
        appliedOn
        __typename
      }
      previouslyBlacklisted
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listApplicants = /* GraphQL */ `
  query ListApplicants(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelApplicantsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplicants(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        jobID
        designation
        aadharNo
        department
        reasonForReject
        candidateName
        experience
        grade
        email
        phone
        resumeName
        resumeUrl
        probation
        jobMatch
        aboutEmployee
        referralName
        referralID
        referralDepartment
        referralDesignation
        applicationStatus
        currentCtc
        expectedCtc
        currentLocation
        description
        fresher
        skills
        location
        previouslyBlacklisted
        otp
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInterview = /* GraphQL */ `
  query GetInterview($pk: String!, $id: String!) {
    getInterview(pk: $pk, id: $id) {
      pk
      id
      sk
      applicantID
      screeningType
      interviewType
      interviewAssessment {
        assessment
        ratings
        __typename
      }
      interviewPreperation {
        questions
        ratings
        __typename
      }
      remarks
      status
      duration
      score
      eligibility
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listInterviews = /* GraphQL */ `
  query ListInterviews(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviews(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        sk
        applicantID
        screeningType
        interviewType
        remarks
        status
        duration
        score
        eligibility
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSocialMediaIntegration = /* GraphQL */ `
  query GetSocialMediaIntegration($pk: String!, $id: String!) {
    getSocialMediaIntegration(pk: $pk, id: $id) {
      pk
      id
      socialMedia
      url
      accessToken
      logoName
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSocialMediaIntegrations = /* GraphQL */ `
  query ListSocialMediaIntegrations(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelSocialMediaIntegrationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSocialMediaIntegrations(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        socialMedia
        url
        accessToken
        logoName
        logoUrl
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHiredEmployeeDetails = /* GraphQL */ `
  query GetHiredEmployeeDetails($pk: String!, $id: String!) {
    getHiredEmployeeDetails(pk: $pk, id: $id) {
      pk
      id
      title
      employeeName
      empImage
      empImageName
      companyName
      designationId
      designationName
      departmentId
      departmentName
      DOJ
      officialEmail
      otherDetails {
        aadharNumber
        aadharDocName
        aadharDocUrl
        panNumber
        panDocName
        panDocUrl
        passportNumber
        passportDocName
        passportDocUrl
        drivingLicence
        licenceExpiry
        insuranceNumber
        renewalPeriod
        verificationDoneBy
        verificationDate
        reportUrl
        reportDocName
        __typename
      }
      fresher
      probationCompleted
      probationCompletedOn
      probation
      experience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      education {
        courseTitle
        subject
        institute
        college
        durationFrom
        durationTo
        educationType
        documentUrl
        documentName
        courseType
        percentage
        __typename
      }
      status
      bloodGroup
      dob
      gender
      nationality
      mobNumber
      alternateMobNumber
      primaryContactName
      primaryContactRelation
      primaryContactNumber
      secondaryContactName
      secondaryContactRelation
      secondaryContactNumber
      marriedStatus
      language {
        languageName
        read
        write
        speak
        native
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      selfService
      mandatoryPasswordReset
      shift
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listHiredEmployeeDetails = /* GraphQL */ `
  query ListHiredEmployeeDetails(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelHiredEmployeeDetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHiredEmployeeDetails(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        title
        employeeName
        empImage
        empImageName
        companyName
        designationId
        designationName
        departmentId
        departmentName
        DOJ
        officialEmail
        fresher
        probationCompleted
        probationCompletedOn
        probation
        status
        bloodGroup
        dob
        gender
        nationality
        mobNumber
        alternateMobNumber
        primaryContactName
        primaryContactRelation
        primaryContactNumber
        secondaryContactName
        secondaryContactRelation
        secondaryContactNumber
        marriedStatus
        selfService
        mandatoryPasswordReset
        shift
        searchKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobOffer = /* GraphQL */ `
  query GetJobOffer($pk: String!, $id: String!) {
    getJobOffer(pk: $pk, id: $id) {
      pk
      id
      department
      designation
      startDate
      location
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      CTC
      deadline
      message
      terms
      confirmationDate
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJobOffers = /* GraphQL */ `
  query ListJobOffers(
    $pk: String
    $id: ModelStringKeyConditionInput
    $filter: ModelJobOfferFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobOffers(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        department
        designation
        startDate
        location
        totalEarnings
        totalDeduction
        CTC
        deadline
        message
        terms
        confirmationDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTokenDetails = /* GraphQL */ `
  query GetTokenDetails($pk: String!, $id: ID!) {
    getTokenDetails(pk: $pk, id: $id) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTokenDetails = /* GraphQL */ `
  query ListTokenDetails(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelTokenDetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTokenDetails(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        expiresIn
        token
        tokenType
        userID
        loginAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tokenByUserId = /* GraphQL */ `
  query TokenByUserId(
    $pk: String!
    $userID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTokenDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tokenByUserId(
      pk: $pk
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        id
        expiresIn
        token
        tokenType
        userID
        loginAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDemoBook = /* GraphQL */ `
  query GetDemoBook($pk: String!, $id: ID!) {
    getDemoBook(pk: $pk, id: $id) {
      pk
      id
      name
      noOfEmployees
      email
      requirements
      phoneNumber
      companyName
      country
      searchKey
      requestedDate
      status
      emailSent
      emailSentBy
      demoDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDemoBooks = /* GraphQL */ `
  query ListDemoBooks(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelDemoBookFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDemoBooks(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        name
        noOfEmployees
        email
        requirements
        phoneNumber
        companyName
        country
        searchKey
        requestedDate
        status
        emailSent
        emailSentBy
        demoDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactUs = /* GraphQL */ `
  query GetContactUs($pk: String!, $id: ID!) {
    getContactUs(pk: $pk, id: $id) {
      pk
      id
      name
      email
      phoneNumber
      companyName
      message
      status
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listContactuses = /* GraphQL */ `
  query ListContactuses(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelContactUsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContactuses(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        name
        email
        phoneNumber
        companyName
        message
        status
        emailSent
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOtp = /* GraphQL */ `
  query GetOtp($pk: String!, $id: ID!) {
    getOtp(pk: $pk, id: $id) {
      pk
      id
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOtps = /* GraphQL */ `
  query ListOtps(
    $pk: String
    $id: ModelIDKeyConditionInput
    $filter: ModelOtpFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOtps(
      pk: $pk
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        id
        otp
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
