// ContactSales.js
import React, { useState, useEffect } from "react";
import { Amplify, API } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { createContactUs } from "../../graphql/mutations";
import { validateForm } from "./contactValidation";
import { Contact } from "./contact";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";

Amplify.configure(awsconfig);

function ContactSales() {
  const initialFormState = {
    pk: "CONTUS#",
    id: uuid(),
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    message: "",
    captcha: "",
    status: "Pending",
  };

  const [formValue, setFormValue] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [responseData, setResponseData] = useState(null);
  const [generatedCaptcha, setGeneratedCaptcha] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const errors = validateForm(formValue, generatedCaptcha);
    setIsFormValid(Object.keys(errors).length === 0);
  }, [formValue, generatedCaptcha]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const fieldErrors = validateForm(
      { ...formValue, [name]: value },
      generatedCaptcha,
      name
    );

    // Remove the error message if the field is valid
    if (!fieldErrors[name]) {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ...fieldErrors,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formValue, generatedCaptcha);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const { captcha, ...formValue_ } = formValue;
      try {
        await API.graphql({
          query: createContactUs,
          authMode: "AWS_IAM",
          variables: {
            input: formValue_,
          },
        }).then((response) => {
          setResponseData({ data: response, severity: "success" });
          setFormErrors({});
          setFormValue(initialFormState);
          setGeneratedCaptcha("");
        });
      } catch (err) {
        setResponseData({ data: err, severity: "error" });
        setFormErrors({});
        setFormValue(initialFormState);
        setGeneratedCaptcha("");
      }
    }
  };

  useEffect(() => {
    if (responseData) {
      if (responseData.severity === "success") {
        toast.success("Successfully sent!", {
          theme: "colored",
          autoClose: true,
        });
        setFormValue(initialFormState);
      } else if (responseData.severity === "error") {
        toast.error("Failed to send. Please try again.", {
          theme: "colored",
          autoClose: true,
        });
      }
    }
  }, [responseData]);

  return (
    <>
      <Contact
        formValue={formValue}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        formErrors={formErrors}
        responseData={responseData}
        setGeneratedCaptcha={setGeneratedCaptcha}
        isFormValid={isFormValid}
      />
    </>
  );
}

export default ContactSales;
