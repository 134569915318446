import React from "react";
import getInTouchImg from "../../assets/images/getInTouch.png";
import ContactForm from "./contactForm";

export const Contact = ({
  formValue,
  handleSubmit,
  handleChange,
  formErrors,
  responseData,
  setGeneratedCaptcha,
  isFormValid,
}) => {
  return (
    <div className="row gx-4">
      <div className="col-lg-5 text-start mb-3">
        <h2>Let's Get in Touch</h2>
        <p>
          Whether you're exploring our plans, have questions about
          implementation, or simply want to discuss how OpticalHR can transform
          your HR processes, we're here to help. Reach out to us today, and
          let's embark on this exciting journey together.
        </p>
        <img src={getInTouchImg} alt="Features" />
      </div>
      <div className="col-lg-1 mb-3"></div>

      {/* Contact Form */}

      <ContactForm
        responseData={responseData}
        formValue={formValue}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        formErrors={formErrors}
        setGeneratedCaptcha={setGeneratedCaptcha}
        isFormValid={isFormValid}
      />
    </div>
  );
};
