import React, { Component, useState, useEffect } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import webScreenImg1 from "../src/assets/images/carousel-1.png";
import webScreenImg2 from "../src/assets/images/carousel-2.png";
import webScreenImg3 from "../src/assets/images/carousel-3.png";
const getTouches = (evt) => {
    return (
        evt.touches || evt.originalEvent.touches // browser API
    );
};
const Carousel3D = () => {
    const [goToSlide, setGoToSlide] = useState(0);
    const [offsetRadius, setOffsetRadius] = useState(2);
    const [showNavigation, setShowNavigation] = useState(true);
    const [enableSwipe, setEnableSwipe] = useState(true);
    const [configs, setConfig] = useState(config.gentle);
    const [intervalId, setIntervalId] = useState(null);
    const slides = [
        {
            key: uuidv4(),
            content: <img src={webScreenImg1} alt="Web Screen Image" />,
        },
        {
            key: uuidv4(),
            content: <img src={webScreenImg2} alt="Web Screen Image" />,
        },
        {
            key: uuidv4(),
            content: <img src={webScreenImg3} alt="Web Screen Image" />,
        },
    ].map((slide, index) => {
        return { ...slide, onClick: () => setGoToSlide(index) };
    });
    useEffect(() => {
        const intervalId = setInterval(() => {
            setGoToSlide((prevSlide) =>
                prevSlide < slides.length - 1 ? prevSlide + 1 : 0
            );
        }, 3000); // Change slide every 3 seconds
        setIntervalId(intervalId); // Store the interval ID
        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);
    const onChangeInput = (e) => {
        this.setState({
            [e.target.name]: parseInt(e.target.value, 10) || 0,
        });
    };
    const handleTouchStart = (evt) => {
        if (!this.state.enableSwipe) {
            return;
        }
        const firstTouch = getTouches(evt)[0];
        this.setState({
            ...this.state,
            xDown: firstTouch.clientX,
            yDown: firstTouch.clientY,
        });
    };
    const handleTouchMove = (evt) => {
        if (!this.state.enableSwipe || (!this.state.xDown && !this.state.yDown)) {
            return;
        }
        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;
        let xDiff = this.state.xDown - xUp;
        let yDiff = this.state.yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                /* left swipe */
                this.setState({
                    goToSlide: this.state.goToSlide + 1,
                    xDown: null,
                    yDown: null,
                });
            } else {
                /* right swipe */
                this.setState({
                    goToSlide: this.state.goToSlide - 1,
                    xDown: null,
                    yDown: null,
                });
            }
        }
    };
    return (
        <div
            style={{ width: "90%", height: "500px", margin: "0 auto" }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            <Carousel
                slides={slides}
                goToSlide={goToSlide}
                offsetRadius={offsetRadius}
                animationConfig={config}
                autoPlay={true} // Enable auto-play
                interval={3000}
            />
        </div>
    );
};
export default Carousel3D;