import React from "react";
import ReactCaptchaa from "react-captchaa";
const BookDemoForm = ({
  formValue,
  handleSubmit,
  handleChange,
  formErrors,
  countries,
  employeeOptions,
  setGeneratedCaptcha,
  isFormValid,
}) => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <label className="mb-1">
                Name <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="name"
                value={formValue.name}
                onChange={handleChange}
              />
              {formErrors.name && (
                <div className="text-danger">{formErrors.name}</div>
              )}
            </div>
            <div className="col-lg-12 mb-3">
              <label className="mb-1">
                Email <span className="mandatory">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                name="email"
                value={formValue.email}
                onChange={handleChange}
              />
              {formErrors.email && (
                <div className="text-danger">{formErrors.email}</div>
              )}
            </div>
            <div className="col-lg-12 mb-3">
              <label className="mb-1">
                Phone Number <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Phone Number"
                name="phoneNumber"
                value={formValue.phoneNumber}
                onChange={handleChange}
              />
              {formErrors.phoneNumber && (
                <div className="text-danger">{formErrors.phoneNumber}</div>
              )}
            </div>
            <div className="col-lg-12 mb-3">
              <label className="mb-1">
                Company Name <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Company Name"
                name="companyName"
                value={formValue.companyName}
                onChange={handleChange}
              />
              {formErrors.companyName && (
                <div className="text-danger">{formErrors.companyName}</div>
              )}
            </div>
            <div className="col-lg-12 mb-3">
              <label className="mb-1">
                Country <span className="mandatory">*</span>
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="country" // Add name attribute
                value={formValue.country}
                onChange={handleChange}
              >
                <option value="">Select country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {formErrors.country && (
                <div className="text-danger">{formErrors.country}</div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <label className="mb-1">
                No.of Employees <span className="mandatory">*</span>
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="noOfEmployees"
                value={formValue.noOfEmployees}
                onChange={handleChange}
              >
                <option value="">Select employees</option>
                {employeeOptions.map((employee, index) => (
                  <option key={index} value={employee}>
                    {employee}
                  </option>
                ))}
              </select>
              {formErrors.noOfEmployees && (
                <div className="text-danger">{formErrors.noOfEmployees}</div>
              )}
            </div>
            <div className="col-lg-12 mb-3">
              <label className="mb-1">
                Your Requirements <span className="mandatory">*</span>
              </label>
              <textarea
                className="form-control"
                placeholder="Explain your Requirements"
                name="requirements"
                value={formValue.requirements}
                onChange={handleChange}
              />
              {formErrors.requirements && (
                <div className="text-danger">{formErrors.requirements}</div>
              )}
            </div>
            <div className="col-lg-12 mb-3">
              <div className="d-flex">
                <ReactCaptchaa
                  captchaText={(captchaText) => {
                    setGeneratedCaptcha(captchaText);
                  }}
                  captchaLength={6}
                  height={150}
                  width={280}
                  iconName={"FiRefreshCw"}
                  iconColor={"#007057"}
                  fontSize={"4em"}
                  iconSize={"1em"}
                  containerClassName={"react-captcha"}
                  iconWrapperClassName={"react-captcha-icon-wrapper"}
                  canvasClassName={"react-captcha-canvas"}
                  iconClassName={"react-captcha-icon"}
                  charactersInclude={
                    "0123456789abcdefghijklmnopqrstuvwxzABCDEFGHIJKLMNOPQRSTUVWXYZ!#$%^&"
                  }
                />
              </div>
            </div>
            <div className="col-lg-12 mb-3">
              <label className="mb-1">
                Captcha <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Captcha"
                name="captcha"
                value={formValue.captcha}
                onChange={handleChange}
              />
              {formErrors.captcha && (
                <div className="text-danger">{formErrors.captcha}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn book-demo-btn mb-3 pt-0"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          Continue
          <span className="material-symbols-rounded">arrow_forward</span>
        </button>
      </div>
    </>
  );
};

export default BookDemoForm;
