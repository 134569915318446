/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      pk
      id
      companyName
      subscriptionID
      subdomain
      subscriptionDetails {
        plan
        amount
        subscriptionPeriod
        currency
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      employeeCount
      searchKey
      companyLogo
      telephone
      email
      fax
      website
      status
      createdBy
      legalName
      displayName
      registrationNumber
      tin
      companyStrength
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      pk
      id
      companyName
      subscriptionID
      subdomain
      subscriptionDetails {
        plan
        amount
        subscriptionPeriod
        currency
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      employeeCount
      searchKey
      companyLogo
      telephone
      email
      fax
      website
      status
      createdBy
      legalName
      displayName
      registrationNumber
      tin
      companyStrength
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      pk
      id
      companyName
      subscriptionID
      subdomain
      subscriptionDetails {
        plan
        amount
        subscriptionPeriod
        currency
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      employeeCount
      searchKey
      companyLogo
      telephone
      email
      fax
      website
      status
      createdBy
      legalName
      displayName
      registrationNumber
      tin
      companyStrength
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      pk
      id
      departmentName
      description
      noOfEmployee
      searchKey
      lead
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      pk
      id
      departmentName
      description
      noOfEmployee
      searchKey
      lead
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      pk
      id
      departmentName
      description
      noOfEmployee
      searchKey
      lead
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDesignation = /* GraphQL */ `
  mutation CreateDesignation(
    $input: CreateDesignationInput!
    $condition: ModelDesignationConditionInput
  ) {
    createDesignation(input: $input, condition: $condition) {
      pk
      id
      title
      level
      description
      noOfEmployee
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDesignation = /* GraphQL */ `
  mutation UpdateDesignation(
    $input: UpdateDesignationInput!
    $condition: ModelDesignationConditionInput
  ) {
    updateDesignation(input: $input, condition: $condition) {
      pk
      id
      title
      level
      description
      noOfEmployee
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDesignation = /* GraphQL */ `
  mutation DeleteDesignation(
    $input: DeleteDesignationInput!
    $condition: ModelDesignationConditionInput
  ) {
    deleteDesignation(input: $input, condition: $condition) {
      pk
      id
      title
      level
      description
      noOfEmployee
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGrade = /* GraphQL */ `
  mutation CreateGrade(
    $input: CreateGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    createGrade(input: $input, condition: $condition) {
      pk
      id
      title
      designation
      CTC
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGrade = /* GraphQL */ `
  mutation UpdateGrade(
    $input: UpdateGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    updateGrade(input: $input, condition: $condition) {
      pk
      id
      title
      designation
      CTC
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGrade = /* GraphQL */ `
  mutation DeleteGrade(
    $input: DeleteGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    deleteGrade(input: $input, condition: $condition) {
      pk
      id
      title
      designation
      CTC
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLeaveMaster = /* GraphQL */ `
  mutation CreateLeaveMaster(
    $input: CreateLeaveMasterInput!
    $condition: ModelLeaveMasterConditionInput
  ) {
    createLeaveMaster(input: $input, condition: $condition) {
      pk
      id
      leaveTitle
      shortCode
      noOfDays
      searchKey
      applicable
      transferable
      cashable
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLeaveMaster = /* GraphQL */ `
  mutation UpdateLeaveMaster(
    $input: UpdateLeaveMasterInput!
    $condition: ModelLeaveMasterConditionInput
  ) {
    updateLeaveMaster(input: $input, condition: $condition) {
      pk
      id
      leaveTitle
      shortCode
      noOfDays
      searchKey
      applicable
      transferable
      cashable
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLeaveMaster = /* GraphQL */ `
  mutation DeleteLeaveMaster(
    $input: DeleteLeaveMasterInput!
    $condition: ModelLeaveMasterConditionInput
  ) {
    deleteLeaveMaster(input: $input, condition: $condition) {
      pk
      id
      leaveTitle
      shortCode
      noOfDays
      searchKey
      applicable
      transferable
      cashable
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      pk
      id
      userID
      employeeName
      empImage
      empImageName
      companyId
      companyName
      designationId
      designationName
      departmentId
      departmentName
      DOJ
      officialEmail
      reportingTo
      overtimeEligible
      otherDetails {
        aadharNumber
        aadharDocName
        aadharDocUrl
        panNumber
        panDocName
        panDocUrl
        passportNumber
        passportDocName
        passportDocUrl
        drivingLicence
        licenceExpiry
        insuranceNumber
        renewalPeriod
        verificationDoneBy
        verificationDate
        reportUrl
        reportDocName
        __typename
      }
      fresher
      probationCompleted
      probationCompletedOn
      probation
      experience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      education {
        courseTitle
        subject
        institute
        college
        durationFrom
        durationTo
        educationType
        documentUrl
        documentName
        courseType
        percentage
        __typename
      }
      status
      bloodGroup
      dob
      gender
      nationality
      personalEmail
      mobNumber
      primaryContactName
      primaryContactRelation
      primaryContactNumber
      secondaryContactName
      secondaryContactRelation
      secondaryContactNumber
      familyStatus
      marriedStatus
      language {
        languageName
        read
        write
        speak
        native
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      selfService
      mandatoryPasswordReset
      shift
      searchKey
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      employeePF
      employeeESI
      employeeTDS
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      pk
      id
      userID
      employeeName
      empImage
      empImageName
      companyId
      companyName
      designationId
      designationName
      departmentId
      departmentName
      DOJ
      officialEmail
      reportingTo
      overtimeEligible
      otherDetails {
        aadharNumber
        aadharDocName
        aadharDocUrl
        panNumber
        panDocName
        panDocUrl
        passportNumber
        passportDocName
        passportDocUrl
        drivingLicence
        licenceExpiry
        insuranceNumber
        renewalPeriod
        verificationDoneBy
        verificationDate
        reportUrl
        reportDocName
        __typename
      }
      fresher
      probationCompleted
      probationCompletedOn
      probation
      experience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      education {
        courseTitle
        subject
        institute
        college
        durationFrom
        durationTo
        educationType
        documentUrl
        documentName
        courseType
        percentage
        __typename
      }
      status
      bloodGroup
      dob
      gender
      nationality
      personalEmail
      mobNumber
      primaryContactName
      primaryContactRelation
      primaryContactNumber
      secondaryContactName
      secondaryContactRelation
      secondaryContactNumber
      familyStatus
      marriedStatus
      language {
        languageName
        read
        write
        speak
        native
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      selfService
      mandatoryPasswordReset
      shift
      searchKey
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      employeePF
      employeeESI
      employeeTDS
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      pk
      id
      userID
      employeeName
      empImage
      empImageName
      companyId
      companyName
      designationId
      designationName
      departmentId
      departmentName
      DOJ
      officialEmail
      reportingTo
      overtimeEligible
      otherDetails {
        aadharNumber
        aadharDocName
        aadharDocUrl
        panNumber
        panDocName
        panDocUrl
        passportNumber
        passportDocName
        passportDocUrl
        drivingLicence
        licenceExpiry
        insuranceNumber
        renewalPeriod
        verificationDoneBy
        verificationDate
        reportUrl
        reportDocName
        __typename
      }
      fresher
      probationCompleted
      probationCompletedOn
      probation
      experience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      education {
        courseTitle
        subject
        institute
        college
        durationFrom
        durationTo
        educationType
        documentUrl
        documentName
        courseType
        percentage
        __typename
      }
      status
      bloodGroup
      dob
      gender
      nationality
      personalEmail
      mobNumber
      primaryContactName
      primaryContactRelation
      primaryContactNumber
      secondaryContactName
      secondaryContactRelation
      secondaryContactNumber
      familyStatus
      marriedStatus
      language {
        languageName
        read
        write
        speak
        native
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      selfService
      mandatoryPasswordReset
      shift
      searchKey
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      employeePF
      employeeESI
      employeeTDS
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRoleManagement = /* GraphQL */ `
  mutation CreateRoleManagement(
    $input: CreateRoleManagementInput!
    $condition: ModelRoleManagementConditionInput
  ) {
    createRoleManagement(input: $input, condition: $condition) {
      pk
      id
      RoleName
      moduleHrms {
        moduleName
        fullAccess
        readOnly
        create
        delete
        __typename
      }
      moduleRecruitment {
        moduleName
        fullAccess
        readOnly
        create
        delete
        __typename
      }
      searchKey
      status
      createdBy
      updated_by
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRoleManagement = /* GraphQL */ `
  mutation UpdateRoleManagement(
    $input: UpdateRoleManagementInput!
    $condition: ModelRoleManagementConditionInput
  ) {
    updateRoleManagement(input: $input, condition: $condition) {
      pk
      id
      RoleName
      moduleHrms {
        moduleName
        fullAccess
        readOnly
        create
        delete
        __typename
      }
      moduleRecruitment {
        moduleName
        fullAccess
        readOnly
        create
        delete
        __typename
      }
      searchKey
      status
      createdBy
      updated_by
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRoleManagement = /* GraphQL */ `
  mutation DeleteRoleManagement(
    $input: DeleteRoleManagementInput!
    $condition: ModelRoleManagementConditionInput
  ) {
    deleteRoleManagement(input: $input, condition: $condition) {
      pk
      id
      RoleName
      moduleHrms {
        moduleName
        fullAccess
        readOnly
        create
        delete
        __typename
      }
      moduleRecruitment {
        moduleName
        fullAccess
        readOnly
        create
        delete
        __typename
      }
      searchKey
      status
      createdBy
      updated_by
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createShift = /* GraphQL */ `
  mutation CreateShift(
    $input: CreateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    createShift(input: $input, condition: $condition) {
      pk
      id
      shiftCode
      name
      startTime
      endTime
      weeklyDays
      status
      description
      searchKey
      secondSaturday
      alternateSaturdays
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateShift = /* GraphQL */ `
  mutation UpdateShift(
    $input: UpdateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    updateShift(input: $input, condition: $condition) {
      pk
      id
      shiftCode
      name
      startTime
      endTime
      weeklyDays
      status
      description
      searchKey
      secondSaturday
      alternateSaturdays
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteShift = /* GraphQL */ `
  mutation DeleteShift(
    $input: DeleteShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    deleteShift(input: $input, condition: $condition) {
      pk
      id
      shiftCode
      name
      startTime
      endTime
      weeklyDays
      status
      description
      searchKey
      secondSaturday
      alternateSaturdays
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployeeAppraisal = /* GraphQL */ `
  mutation CreateEmployeeAppraisal(
    $input: CreateEmployeeAppraisalInput!
    $condition: ModelEmployeeAppraisalConditionInput
  ) {
    createEmployeeAppraisal(input: $input, condition: $condition) {
      pk
      id
      sk
      appraisalType
      promotedTo
      departmentId
      departmentName
      promotionDate
      appraisalScore
      comments
      reviews
      attachmentUrl
      attachmentName
      effectivefrom
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      previousDetails {
        designation
        reviews
        appraisalScore
        totalEarnings
        totalDeduction
        __typename
      }
      confirmationLetterpdf
      appraisalLetterpd
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployeeAppraisal = /* GraphQL */ `
  mutation UpdateEmployeeAppraisal(
    $input: UpdateEmployeeAppraisalInput!
    $condition: ModelEmployeeAppraisalConditionInput
  ) {
    updateEmployeeAppraisal(input: $input, condition: $condition) {
      pk
      id
      sk
      appraisalType
      promotedTo
      departmentId
      departmentName
      promotionDate
      appraisalScore
      comments
      reviews
      attachmentUrl
      attachmentName
      effectivefrom
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      previousDetails {
        designation
        reviews
        appraisalScore
        totalEarnings
        totalDeduction
        __typename
      }
      confirmationLetterpdf
      appraisalLetterpd
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployeeAppraisal = /* GraphQL */ `
  mutation DeleteEmployeeAppraisal(
    $input: DeleteEmployeeAppraisalInput!
    $condition: ModelEmployeeAppraisalConditionInput
  ) {
    deleteEmployeeAppraisal(input: $input, condition: $condition) {
      pk
      id
      sk
      appraisalType
      promotedTo
      departmentId
      departmentName
      promotionDate
      appraisalScore
      comments
      reviews
      attachmentUrl
      attachmentName
      effectivefrom
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      previousDetails {
        designation
        reviews
        appraisalScore
        totalEarnings
        totalDeduction
        __typename
      }
      confirmationLetterpdf
      appraisalLetterpd
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployeeDocument = /* GraphQL */ `
  mutation CreateEmployeeDocument(
    $input: CreateEmployeeDocumentInput!
    $condition: ModelEmployeeDocumentConditionInput
  ) {
    createEmployeeDocument(input: $input, condition: $condition) {
      pk
      id
      sk
      documentType
      expiry
      documentUrl
      docName
      visibleToEmployee
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployeeDocument = /* GraphQL */ `
  mutation UpdateEmployeeDocument(
    $input: UpdateEmployeeDocumentInput!
    $condition: ModelEmployeeDocumentConditionInput
  ) {
    updateEmployeeDocument(input: $input, condition: $condition) {
      pk
      id
      sk
      documentType
      expiry
      documentUrl
      docName
      visibleToEmployee
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployeeDocument = /* GraphQL */ `
  mutation DeleteEmployeeDocument(
    $input: DeleteEmployeeDocumentInput!
    $condition: ModelEmployeeDocumentConditionInput
  ) {
    deleteEmployeeDocument(input: $input, condition: $condition) {
      pk
      id
      sk
      documentType
      expiry
      documentUrl
      docName
      visibleToEmployee
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployeeFamily = /* GraphQL */ `
  mutation CreateEmployeeFamily(
    $input: CreateEmployeeFamilyInput!
    $condition: ModelEmployeeFamilyConditionInput
  ) {
    createEmployeeFamily(input: $input, condition: $condition) {
      pk
      id
      sk
      name
      relationShip
      documentType
      expiryDate
      attachmentUrl
      attachmentName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployeeFamily = /* GraphQL */ `
  mutation UpdateEmployeeFamily(
    $input: UpdateEmployeeFamilyInput!
    $condition: ModelEmployeeFamilyConditionInput
  ) {
    updateEmployeeFamily(input: $input, condition: $condition) {
      pk
      id
      sk
      name
      relationShip
      documentType
      expiryDate
      attachmentUrl
      attachmentName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployeeFamily = /* GraphQL */ `
  mutation DeleteEmployeeFamily(
    $input: DeleteEmployeeFamilyInput!
    $condition: ModelEmployeeFamilyConditionInput
  ) {
    deleteEmployeeFamily(input: $input, condition: $condition) {
      pk
      id
      sk
      name
      relationShip
      documentType
      expiryDate
      attachmentUrl
      attachmentName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployeeSeperation = /* GraphQL */ `
  mutation CreateEmployeeSeperation(
    $input: CreateEmployeeSeperationInput!
    $condition: ModelEmployeeSeperationConditionInput
  ) {
    createEmployeeSeperation(input: $input, condition: $condition) {
      pk
      id
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      seperationReason
      handoverTo
      issuedDate
      releavingDate
      reason
      resignationLetter
      otherEarningsReason
      otherDeductionsReason
      finalSettlementLetterPDF
      attachmentUrl
      releavingLetterPDF
      experienceLetterPDF
      finalSettlementLetter
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployeeSeperation = /* GraphQL */ `
  mutation UpdateEmployeeSeperation(
    $input: UpdateEmployeeSeperationInput!
    $condition: ModelEmployeeSeperationConditionInput
  ) {
    updateEmployeeSeperation(input: $input, condition: $condition) {
      pk
      id
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      seperationReason
      handoverTo
      issuedDate
      releavingDate
      reason
      resignationLetter
      otherEarningsReason
      otherDeductionsReason
      finalSettlementLetterPDF
      attachmentUrl
      releavingLetterPDF
      experienceLetterPDF
      finalSettlementLetter
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployeeSeperation = /* GraphQL */ `
  mutation DeleteEmployeeSeperation(
    $input: DeleteEmployeeSeperationInput!
    $condition: ModelEmployeeSeperationConditionInput
  ) {
    deleteEmployeeSeperation(input: $input, condition: $condition) {
      pk
      id
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      seperationReason
      handoverTo
      issuedDate
      releavingDate
      reason
      resignationLetter
      otherEarningsReason
      otherDeductionsReason
      finalSettlementLetterPDF
      attachmentUrl
      releavingLetterPDF
      experienceLetterPDF
      finalSettlementLetter
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployeeLoan = /* GraphQL */ `
  mutation CreateEmployeeLoan(
    $input: CreateEmployeeLoanInput!
    $condition: ModelEmployeeLoanConditionInput
  ) {
    createEmployeeLoan(input: $input, condition: $condition) {
      pk
      id
      sk
      loanAmount
      approvedAmount
      noOfInstalment
      employeeName
      empImage
      extendedDuration
      paymentStartdate
      paymentEnddate
      status
      instalmentDetails {
        paymentDate
        amount
        paymentStatus
        __typename
      }
      instalmentAmount
      type
      subject
      description
      document
      reportingTo
      approvalDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployeeLoan = /* GraphQL */ `
  mutation UpdateEmployeeLoan(
    $input: UpdateEmployeeLoanInput!
    $condition: ModelEmployeeLoanConditionInput
  ) {
    updateEmployeeLoan(input: $input, condition: $condition) {
      pk
      id
      sk
      loanAmount
      approvedAmount
      noOfInstalment
      employeeName
      empImage
      extendedDuration
      paymentStartdate
      paymentEnddate
      status
      instalmentDetails {
        paymentDate
        amount
        paymentStatus
        __typename
      }
      instalmentAmount
      type
      subject
      description
      document
      reportingTo
      approvalDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployeeLoan = /* GraphQL */ `
  mutation DeleteEmployeeLoan(
    $input: DeleteEmployeeLoanInput!
    $condition: ModelEmployeeLoanConditionInput
  ) {
    deleteEmployeeLoan(input: $input, condition: $condition) {
      pk
      id
      sk
      loanAmount
      approvedAmount
      noOfInstalment
      employeeName
      empImage
      extendedDuration
      paymentStartdate
      paymentEnddate
      status
      instalmentDetails {
        paymentDate
        amount
        paymentStatus
        __typename
      }
      instalmentAmount
      type
      subject
      description
      document
      reportingTo
      approvalDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployeeSalary = /* GraphQL */ `
  mutation CreateEmployeeSalary(
    $input: CreateEmployeeSalaryInput!
    $condition: ModelEmployeeSalaryConditionInput
  ) {
    createEmployeeSalary(input: $input, condition: $condition) {
      pk
      id
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployeeSalary = /* GraphQL */ `
  mutation UpdateEmployeeSalary(
    $input: UpdateEmployeeSalaryInput!
    $condition: ModelEmployeeSalaryConditionInput
  ) {
    updateEmployeeSalary(input: $input, condition: $condition) {
      pk
      id
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployeeSalary = /* GraphQL */ `
  mutation DeleteEmployeeSalary(
    $input: DeleteEmployeeSalaryInput!
    $condition: ModelEmployeeSalaryConditionInput
  ) {
    deleteEmployeeSalary(input: $input, condition: $condition) {
      pk
      id
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCountryDetails = /* GraphQL */ `
  mutation CreateCountryDetails(
    $input: CreateCountryDetailsInput!
    $condition: ModelCountryDetailsConditionInput
  ) {
    createCountryDetails(input: $input, condition: $condition) {
      pk
      id
      country
      state
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCountryDetails = /* GraphQL */ `
  mutation UpdateCountryDetails(
    $input: UpdateCountryDetailsInput!
    $condition: ModelCountryDetailsConditionInput
  ) {
    updateCountryDetails(input: $input, condition: $condition) {
      pk
      id
      country
      state
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCountryDetails = /* GraphQL */ `
  mutation DeleteCountryDetails(
    $input: DeleteCountryDetailsInput!
    $condition: ModelCountryDetailsConditionInput
  ) {
    deleteCountryDetails(input: $input, condition: $condition) {
      pk
      id
      country
      state
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDailyAttendance = /* GraphQL */ `
  mutation CreateDailyAttendance(
    $input: CreateDailyAttendanceInput!
    $condition: ModelDailyAttendanceConditionInput
  ) {
    createDailyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      loginType
      inLocation
      outLocation
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      currentdate
      shift
      shiftFromTime
      shiftToTime
      intime
      outtime
      islate
      isearly
      break {
        in
        out
        __typename
      }
      overtime
      workingHours
      overtimeEligible
      totalDuration
      latitude
      longitude
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDailyAttendance = /* GraphQL */ `
  mutation UpdateDailyAttendance(
    $input: UpdateDailyAttendanceInput!
    $condition: ModelDailyAttendanceConditionInput
  ) {
    updateDailyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      loginType
      inLocation
      outLocation
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      currentdate
      shift
      shiftFromTime
      shiftToTime
      intime
      outtime
      islate
      isearly
      break {
        in
        out
        __typename
      }
      overtime
      workingHours
      overtimeEligible
      totalDuration
      latitude
      longitude
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDailyAttendance = /* GraphQL */ `
  mutation DeleteDailyAttendance(
    $input: DeleteDailyAttendanceInput!
    $condition: ModelDailyAttendanceConditionInput
  ) {
    deleteDailyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      loginType
      inLocation
      outLocation
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      currentdate
      shift
      shiftFromTime
      shiftToTime
      intime
      outtime
      islate
      isearly
      break {
        in
        out
        __typename
      }
      overtime
      workingHours
      overtimeEligible
      totalDuration
      latitude
      longitude
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPayrollHeads = /* GraphQL */ `
  mutation CreatePayrollHeads(
    $input: CreatePayrollHeadsInput!
    $condition: ModelPayrollHeadsConditionInput
  ) {
    createPayrollHeads(input: $input, condition: $condition) {
      pk
      id
      headName
      category
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePayrollHeads = /* GraphQL */ `
  mutation UpdatePayrollHeads(
    $input: UpdatePayrollHeadsInput!
    $condition: ModelPayrollHeadsConditionInput
  ) {
    updatePayrollHeads(input: $input, condition: $condition) {
      pk
      id
      headName
      category
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePayrollHeads = /* GraphQL */ `
  mutation DeletePayrollHeads(
    $input: DeletePayrollHeadsInput!
    $condition: ModelPayrollHeadsConditionInput
  ) {
    deletePayrollHeads(input: $input, condition: $condition) {
      pk
      id
      headName
      category
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWeeklyAttendance = /* GraphQL */ `
  mutation CreateWeeklyAttendance(
    $input: CreateWeeklyAttendanceInput!
    $condition: ModelWeeklyAttendanceConditionInput
  ) {
    createWeeklyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      month
      year
      week
      totalworkingdays
      fromdate
      todate
      overtimeEligible
      attendanceDetails {
        currentdate
        timein
        timeout
        status
        overtime
        islate
        isearly
        __typename
      }
      totalPresent
      totalAbsent
      totalLeaves
      totalDuration
      totalOvertime
      totalLateby
      totalEarnedLeaves
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWeeklyAttendance = /* GraphQL */ `
  mutation UpdateWeeklyAttendance(
    $input: UpdateWeeklyAttendanceInput!
    $condition: ModelWeeklyAttendanceConditionInput
  ) {
    updateWeeklyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      month
      year
      week
      totalworkingdays
      fromdate
      todate
      overtimeEligible
      attendanceDetails {
        currentdate
        timein
        timeout
        status
        overtime
        islate
        isearly
        __typename
      }
      totalPresent
      totalAbsent
      totalLeaves
      totalDuration
      totalOvertime
      totalLateby
      totalEarnedLeaves
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWeeklyAttendance = /* GraphQL */ `
  mutation DeleteWeeklyAttendance(
    $input: DeleteWeeklyAttendanceInput!
    $condition: ModelWeeklyAttendanceConditionInput
  ) {
    deleteWeeklyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      month
      year
      week
      totalworkingdays
      fromdate
      todate
      overtimeEligible
      attendanceDetails {
        currentdate
        timein
        timeout
        status
        overtime
        islate
        isearly
        __typename
      }
      totalPresent
      totalAbsent
      totalLeaves
      totalDuration
      totalOvertime
      totalLateby
      totalEarnedLeaves
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createMonthlyAttendance = /* GraphQL */ `
  mutation CreateMonthlyAttendance(
    $input: CreateMonthlyAttendanceInput!
    $condition: ModelMonthlyAttendanceConditionInput
  ) {
    createMonthlyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      month
      year
      totalworkingdays
      fromdate
      todate
      overtimeEligible
      attendanceDetails {
        currentdate
        timein
        timeout
        status
        overtime
        islate
        isearly
        __typename
      }
      totalPresent
      totalAbsent
      totalLeaves
      totalDuration
      totalOvertime
      totalLateby
      totalEarnedLeaves
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMonthlyAttendance = /* GraphQL */ `
  mutation UpdateMonthlyAttendance(
    $input: UpdateMonthlyAttendanceInput!
    $condition: ModelMonthlyAttendanceConditionInput
  ) {
    updateMonthlyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      month
      year
      totalworkingdays
      fromdate
      todate
      overtimeEligible
      attendanceDetails {
        currentdate
        timein
        timeout
        status
        overtime
        islate
        isearly
        __typename
      }
      totalPresent
      totalAbsent
      totalLeaves
      totalDuration
      totalOvertime
      totalLateby
      totalEarnedLeaves
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMonthlyAttendance = /* GraphQL */ `
  mutation DeleteMonthlyAttendance(
    $input: DeleteMonthlyAttendanceInput!
    $condition: ModelMonthlyAttendanceConditionInput
  ) {
    deleteMonthlyAttendance(input: $input, condition: $condition) {
      pk
      sk
      id
      Type
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      month
      year
      totalworkingdays
      fromdate
      todate
      overtimeEligible
      attendanceDetails {
        currentdate
        timein
        timeout
        status
        overtime
        islate
        isearly
        __typename
      }
      totalPresent
      totalAbsent
      totalLeaves
      totalDuration
      totalOvertime
      totalLateby
      totalEarnedLeaves
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      pk
      sk
      notificationType
      id
      Title
      Message
      employeeID
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      pk
      sk
      notificationType
      id
      Title
      Message
      employeeID
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      pk
      sk
      notificationType
      id
      Title
      Message
      employeeID
      employeeName
      employeeImage
      designationID
      designationName
      departmentID
      departmentName
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createMailLog = /* GraphQL */ `
  mutation CreateMailLog(
    $input: CreateMailLogInput!
    $condition: ModelMailLogConditionInput
  ) {
    createMailLog(input: $input, condition: $condition) {
      pk
      sk
      id
      emailTemplateReference
      referenceID
      userID
      title
      emailTemplate
      mailDetails {
        sentTo
        sentDate
        sentBy
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMailLog = /* GraphQL */ `
  mutation UpdateMailLog(
    $input: UpdateMailLogInput!
    $condition: ModelMailLogConditionInput
  ) {
    updateMailLog(input: $input, condition: $condition) {
      pk
      sk
      id
      emailTemplateReference
      referenceID
      userID
      title
      emailTemplate
      mailDetails {
        sentTo
        sentDate
        sentBy
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMailLog = /* GraphQL */ `
  mutation DeleteMailLog(
    $input: DeleteMailLogInput!
    $condition: ModelMailLogConditionInput
  ) {
    deleteMailLog(input: $input, condition: $condition) {
      pk
      sk
      id
      emailTemplateReference
      referenceID
      userID
      title
      emailTemplate
      mailDetails {
        sentTo
        sentDate
        sentBy
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createHolidayList = /* GraphQL */ `
  mutation CreateHolidayList(
    $input: CreateHolidayListInput!
    $condition: ModelHolidayListConditionInput
  ) {
    createHolidayList(input: $input, condition: $condition) {
      pk
      sk
      id
      year
      date
      day
      title
      holidayType
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateHolidayList = /* GraphQL */ `
  mutation UpdateHolidayList(
    $input: UpdateHolidayListInput!
    $condition: ModelHolidayListConditionInput
  ) {
    updateHolidayList(input: $input, condition: $condition) {
      pk
      sk
      id
      year
      date
      day
      title
      holidayType
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteHolidayList = /* GraphQL */ `
  mutation DeleteHolidayList(
    $input: DeleteHolidayListInput!
    $condition: ModelHolidayListConditionInput
  ) {
    deleteHolidayList(input: $input, condition: $condition) {
      pk
      sk
      id
      year
      date
      day
      title
      holidayType
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAvailableLeaves = /* GraphQL */ `
  mutation CreateAvailableLeaves(
    $input: CreateAvailableLeavesInput!
    $condition: ModelAvailableLeavesConditionInput
  ) {
    createAvailableLeaves(input: $input, condition: $condition) {
      pk
      id
      sk
      companyID
      employeeID
      employeeName
      year
      department
      DOJ
      availableLeaves {
        leaveType
        totalAvailable
        totalTaken
        remaining
        isCarryforward
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAvailableLeaves = /* GraphQL */ `
  mutation UpdateAvailableLeaves(
    $input: UpdateAvailableLeavesInput!
    $condition: ModelAvailableLeavesConditionInput
  ) {
    updateAvailableLeaves(input: $input, condition: $condition) {
      pk
      id
      sk
      companyID
      employeeID
      employeeName
      year
      department
      DOJ
      availableLeaves {
        leaveType
        totalAvailable
        totalTaken
        remaining
        isCarryforward
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAvailableLeaves = /* GraphQL */ `
  mutation DeleteAvailableLeaves(
    $input: DeleteAvailableLeavesInput!
    $condition: ModelAvailableLeavesConditionInput
  ) {
    deleteAvailableLeaves(input: $input, condition: $condition) {
      pk
      id
      sk
      companyID
      employeeID
      employeeName
      year
      department
      DOJ
      availableLeaves {
        leaveType
        totalAvailable
        totalTaken
        remaining
        isCarryforward
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLeaveRequest = /* GraphQL */ `
  mutation CreateLeaveRequest(
    $input: CreateLeaveRequestInput!
    $condition: ModelLeaveRequestConditionInput
  ) {
    createLeaveRequest(input: $input, condition: $condition) {
      pk
      id
      sk
      companyID
      employeeID
      employeeName
      empImage
      reference
      year
      isPaid
      halfDay
      fullDay
      designation
      leaveType
      leaveFrom
      leaveTo
      totalDays
      documentName
      documentUrl
      reasonForLeave
      reasonForReject
      status
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLeaveRequest = /* GraphQL */ `
  mutation UpdateLeaveRequest(
    $input: UpdateLeaveRequestInput!
    $condition: ModelLeaveRequestConditionInput
  ) {
    updateLeaveRequest(input: $input, condition: $condition) {
      pk
      id
      sk
      companyID
      employeeID
      employeeName
      empImage
      reference
      year
      isPaid
      halfDay
      fullDay
      designation
      leaveType
      leaveFrom
      leaveTo
      totalDays
      documentName
      documentUrl
      reasonForLeave
      reasonForReject
      status
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLeaveRequest = /* GraphQL */ `
  mutation DeleteLeaveRequest(
    $input: DeleteLeaveRequestInput!
    $condition: ModelLeaveRequestConditionInput
  ) {
    deleteLeaveRequest(input: $input, condition: $condition) {
      pk
      id
      sk
      companyID
      employeeID
      employeeName
      empImage
      reference
      year
      isPaid
      halfDay
      fullDay
      designation
      leaveType
      leaveFrom
      leaveTo
      totalDays
      documentName
      documentUrl
      reasonForLeave
      reasonForReject
      status
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPayRoll = /* GraphQL */ `
  mutation CreatePayRoll(
    $input: CreatePayRollInput!
    $condition: ModelPayRollConditionInput
  ) {
    createPayRoll(input: $input, condition: $condition) {
      pk
      id
      month
      year
      department
      designation
      employeeID
      employeeName
      employeeESI
      employeePF
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      earnings {
        earningType
        earningAmount
        __typename
      }
      presentdays
      totalDeduction
      totalEarnings
      totalamount
      totalduration
      otherEarnings
      otherDeductions
      createdBy
      workingdays
      searchKey
      status
      overtimeamount
      leavedeductionamount
      loanamount
      paySlipUrl
      TDS
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePayRoll = /* GraphQL */ `
  mutation UpdatePayRoll(
    $input: UpdatePayRollInput!
    $condition: ModelPayRollConditionInput
  ) {
    updatePayRoll(input: $input, condition: $condition) {
      pk
      id
      month
      year
      department
      designation
      employeeID
      employeeName
      employeeESI
      employeePF
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      earnings {
        earningType
        earningAmount
        __typename
      }
      presentdays
      totalDeduction
      totalEarnings
      totalamount
      totalduration
      otherEarnings
      otherDeductions
      createdBy
      workingdays
      searchKey
      status
      overtimeamount
      leavedeductionamount
      loanamount
      paySlipUrl
      TDS
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePayRoll = /* GraphQL */ `
  mutation DeletePayRoll(
    $input: DeletePayRollInput!
    $condition: ModelPayRollConditionInput
  ) {
    deletePayRoll(input: $input, condition: $condition) {
      pk
      id
      month
      year
      department
      designation
      employeeID
      employeeName
      employeeESI
      employeePF
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      earnings {
        earningType
        earningAmount
        __typename
      }
      presentdays
      totalDeduction
      totalEarnings
      totalamount
      totalduration
      otherEarnings
      otherDeductions
      createdBy
      workingdays
      searchKey
      status
      overtimeamount
      leavedeductionamount
      loanamount
      paySlipUrl
      TDS
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTrainingSchedule = /* GraphQL */ `
  mutation CreateTrainingSchedule(
    $input: CreateTrainingScheduleInput!
    $condition: ModelTrainingScheduleConditionInput
  ) {
    createTrainingSchedule(input: $input, condition: $condition) {
      pk
      id
      trainingName
      trainingProvidedBy
      designation
      medium
      startDate
      endDate
      startTime
      endTime
      location
      mode
      attendee
      attended
      attendedBy
      documentName
      documentUrl
      description
      searchKey
      status
      createdBy
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTrainingSchedule = /* GraphQL */ `
  mutation UpdateTrainingSchedule(
    $input: UpdateTrainingScheduleInput!
    $condition: ModelTrainingScheduleConditionInput
  ) {
    updateTrainingSchedule(input: $input, condition: $condition) {
      pk
      id
      trainingName
      trainingProvidedBy
      designation
      medium
      startDate
      endDate
      startTime
      endTime
      location
      mode
      attendee
      attended
      attendedBy
      documentName
      documentUrl
      description
      searchKey
      status
      createdBy
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTrainingSchedule = /* GraphQL */ `
  mutation DeleteTrainingSchedule(
    $input: DeleteTrainingScheduleInput!
    $condition: ModelTrainingScheduleConditionInput
  ) {
    deleteTrainingSchedule(input: $input, condition: $condition) {
      pk
      id
      trainingName
      trainingProvidedBy
      designation
      medium
      startDate
      endDate
      startTime
      endTime
      location
      mode
      attendee
      attended
      attendedBy
      documentName
      documentUrl
      description
      searchKey
      status
      createdBy
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createContacts = /* GraphQL */ `
  mutation CreateContacts(
    $input: CreateContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    createContacts(input: $input, condition: $condition) {
      pk
      id
      sk
      sectionName
      searchKey
      employeeID
      employeeName
      empImage
      gender
      designation
      contactNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateContacts = /* GraphQL */ `
  mutation UpdateContacts(
    $input: UpdateContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    updateContacts(input: $input, condition: $condition) {
      pk
      id
      sk
      sectionName
      searchKey
      employeeID
      employeeName
      empImage
      gender
      designation
      contactNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteContacts = /* GraphQL */ `
  mutation DeleteContacts(
    $input: DeleteContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    deleteContacts(input: $input, condition: $condition) {
      pk
      id
      sk
      sectionName
      searchKey
      employeeID
      employeeName
      empImage
      gender
      designation
      contactNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      pk
      id
      sk
      sectionName
      searchKey
      content
      docUrl
      visibleToEmployee
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      pk
      id
      sk
      sectionName
      searchKey
      content
      docUrl
      visibleToEmployee
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      pk
      id
      sk
      sectionName
      searchKey
      content
      docUrl
      visibleToEmployee
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      pk
      id
      sk
      postedOn
      searchKey
      title
      banner
      docUrl
      docName
      content
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      pk
      id
      sk
      postedOn
      searchKey
      title
      banner
      docUrl
      docName
      content
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      pk
      id
      sk
      postedOn
      searchKey
      title
      banner
      docUrl
      docName
      content
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDropdown = /* GraphQL */ `
  mutation CreateDropdown(
    $input: CreateDropdownInput!
    $condition: ModelDropdownConditionInput
  ) {
    createDropdown(input: $input, condition: $condition) {
      pk
      id
      title
      dropdownType
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDropdown = /* GraphQL */ `
  mutation UpdateDropdown(
    $input: UpdateDropdownInput!
    $condition: ModelDropdownConditionInput
  ) {
    updateDropdown(input: $input, condition: $condition) {
      pk
      id
      title
      dropdownType
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDropdown = /* GraphQL */ `
  mutation DeleteDropdown(
    $input: DeleteDropdownInput!
    $condition: ModelDropdownConditionInput
  ) {
    deleteDropdown(input: $input, condition: $condition) {
      pk
      id
      title
      dropdownType
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserRole = /* GraphQL */ `
  mutation CreateUserRole(
    $input: CreateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    createUserRole(input: $input, condition: $condition) {
      pk
      id
      name
      email
      roleName
      roleID
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole(
    $input: UpdateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    updateUserRole(input: $input, condition: $condition) {
      pk
      id
      name
      email
      roleName
      roleID
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserRole = /* GraphQL */ `
  mutation DeleteUserRole(
    $input: DeleteUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    deleteUserRole(input: $input, condition: $condition) {
      pk
      id
      name
      email
      roleName
      roleID
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createApprovalMaster = /* GraphQL */ `
  mutation CreateApprovalMaster(
    $input: CreateApprovalMasterInput!
    $condition: ModelApprovalMasterConditionInput
  ) {
    createApprovalMaster(input: $input, condition: $condition) {
      pk
      id
      section
      type
      searchKey
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateApprovalMaster = /* GraphQL */ `
  mutation UpdateApprovalMaster(
    $input: UpdateApprovalMasterInput!
    $condition: ModelApprovalMasterConditionInput
  ) {
    updateApprovalMaster(input: $input, condition: $condition) {
      pk
      id
      section
      type
      searchKey
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteApprovalMaster = /* GraphQL */ `
  mutation DeleteApprovalMaster(
    $input: DeleteApprovalMasterInput!
    $condition: ModelApprovalMasterConditionInput
  ) {
    deleteApprovalMaster(input: $input, condition: $condition) {
      pk
      id
      section
      type
      searchKey
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGeneralRequest = /* GraphQL */ `
  mutation CreateGeneralRequest(
    $input: CreateGeneralRequestInput!
    $condition: ModelGeneralRequestConditionInput
  ) {
    createGeneralRequest(input: $input, condition: $condition) {
      pk
      id
      sk
      employeeID
      employeeName
      empImage
      reference
      designation
      type
      subject
      requestedOn
      documentList {
        docName
        docUrl
        __typename
      }
      documentName
      documentUrl
      status
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      searchKey
      description
      doj
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGeneralRequest = /* GraphQL */ `
  mutation UpdateGeneralRequest(
    $input: UpdateGeneralRequestInput!
    $condition: ModelGeneralRequestConditionInput
  ) {
    updateGeneralRequest(input: $input, condition: $condition) {
      pk
      id
      sk
      employeeID
      employeeName
      empImage
      reference
      designation
      type
      subject
      requestedOn
      documentList {
        docName
        docUrl
        __typename
      }
      documentName
      documentUrl
      status
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      searchKey
      description
      doj
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGeneralRequest = /* GraphQL */ `
  mutation DeleteGeneralRequest(
    $input: DeleteGeneralRequestInput!
    $condition: ModelGeneralRequestConditionInput
  ) {
    deleteGeneralRequest(input: $input, condition: $condition) {
      pk
      id
      sk
      employeeID
      employeeName
      empImage
      reference
      designation
      type
      subject
      requestedOn
      documentList {
        docName
        docUrl
        __typename
      }
      documentName
      documentUrl
      status
      approvelDetails {
        levelOfApprovel
        approvedBy
        employeeID
        empImage
        designation
        approvedOn
        remarks
        __typename
      }
      searchKey
      description
      doj
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTDSMaster = /* GraphQL */ `
  mutation CreateTDSMaster(
    $input: CreateTDSMasterInput!
    $condition: ModelTDSMasterConditionInput
  ) {
    createTDSMaster(input: $input, condition: $condition) {
      pk
      id
      tdsName
      description
      searchKey
      exemptionsType
      exemptionLimit
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTDSMaster = /* GraphQL */ `
  mutation UpdateTDSMaster(
    $input: UpdateTDSMasterInput!
    $condition: ModelTDSMasterConditionInput
  ) {
    updateTDSMaster(input: $input, condition: $condition) {
      pk
      id
      tdsName
      description
      searchKey
      exemptionsType
      exemptionLimit
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTDSMaster = /* GraphQL */ `
  mutation DeleteTDSMaster(
    $input: DeleteTDSMasterInput!
    $condition: ModelTDSMasterConditionInput
  ) {
    deleteTDSMaster(input: $input, condition: $condition) {
      pk
      id
      tdsName
      description
      searchKey
      exemptionsType
      exemptionLimit
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmpTDSReference = /* GraphQL */ `
  mutation CreateEmpTDSReference(
    $input: CreateEmpTDSReferenceInput!
    $condition: ModelEmpTDSReferenceConditionInput
  ) {
    createEmpTDSReference(input: $input, condition: $condition) {
      pk
      id
      sk
      year_month
      tdsName
      slabDetails {
        slabType
        limit
        __typename
      }
      exemption {
        exemptionsType
        exemptionAmount
        __typename
      }
      totalExemption
      standardDeduction
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmpTDSReference = /* GraphQL */ `
  mutation UpdateEmpTDSReference(
    $input: UpdateEmpTDSReferenceInput!
    $condition: ModelEmpTDSReferenceConditionInput
  ) {
    updateEmpTDSReference(input: $input, condition: $condition) {
      pk
      id
      sk
      year_month
      tdsName
      slabDetails {
        slabType
        limit
        __typename
      }
      exemption {
        exemptionsType
        exemptionAmount
        __typename
      }
      totalExemption
      standardDeduction
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmpTDSReference = /* GraphQL */ `
  mutation DeleteEmpTDSReference(
    $input: DeleteEmpTDSReferenceInput!
    $condition: ModelEmpTDSReferenceConditionInput
  ) {
    deleteEmpTDSReference(input: $input, condition: $condition) {
      pk
      id
      sk
      year_month
      tdsName
      slabDetails {
        slabType
        limit
        __typename
      }
      exemption {
        exemptionsType
        exemptionAmount
        __typename
      }
      totalExemption
      standardDeduction
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSubscriptionPlan = /* GraphQL */ `
  mutation CreateSubscriptionPlan(
    $input: CreateSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    createSubscriptionPlan(input: $input, condition: $condition) {
      pk
      id
      subscriptionName
      subscriptionType
      validity
      section
      amount
      noOfUsers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSubscriptionPlan = /* GraphQL */ `
  mutation UpdateSubscriptionPlan(
    $input: UpdateSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    updateSubscriptionPlan(input: $input, condition: $condition) {
      pk
      id
      subscriptionName
      subscriptionType
      validity
      section
      amount
      noOfUsers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSubscriptionPlan = /* GraphQL */ `
  mutation DeleteSubscriptionPlan(
    $input: DeleteSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    deleteSubscriptionPlan(input: $input, condition: $condition) {
      pk
      id
      subscriptionName
      subscriptionType
      validity
      section
      amount
      noOfUsers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSubscriptions = /* GraphQL */ `
  mutation CreateSubscriptions(
    $input: CreateSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    createSubscriptions(input: $input, condition: $condition) {
      pk
      id
      subscriptionPlanID
      customerName
      companyName
      renewal
      emailID
      contactNumber
      companyStrength
      companyWebsite
      plan
      companyID
      parentCompanyID
      parentCompanyName
      lastBillingDate
      nextBillingDate
      billingHistory {
        paymentDate
        validFrom
        validTo
        paymentMethod
        amount
        paymentStatus
        __typename
      }
      accountStatus
      referedBy
      subdomain
      paymentDetails {
        paymentType
        upiID
        transactionID
        accountNumber
        bankName
        branch
        ifsc
        cardType
        cardHolder
        cardNumber
        expiry
        cvv
        paymentAmount
        paidOn
        __typename
      }
      billingInfo {
        billingName
        billingAddress
        city
        state
        zipcode
        country
        emailAddress
        phone
        Vat
        __typename
      }
      subscriptionType
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSubscriptions = /* GraphQL */ `
  mutation UpdateSubscriptions(
    $input: UpdateSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    updateSubscriptions(input: $input, condition: $condition) {
      pk
      id
      subscriptionPlanID
      customerName
      companyName
      renewal
      emailID
      contactNumber
      companyStrength
      companyWebsite
      plan
      companyID
      parentCompanyID
      parentCompanyName
      lastBillingDate
      nextBillingDate
      billingHistory {
        paymentDate
        validFrom
        validTo
        paymentMethod
        amount
        paymentStatus
        __typename
      }
      accountStatus
      referedBy
      subdomain
      paymentDetails {
        paymentType
        upiID
        transactionID
        accountNumber
        bankName
        branch
        ifsc
        cardType
        cardHolder
        cardNumber
        expiry
        cvv
        paymentAmount
        paidOn
        __typename
      }
      billingInfo {
        billingName
        billingAddress
        city
        state
        zipcode
        country
        emailAddress
        phone
        Vat
        __typename
      }
      subscriptionType
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSubscriptions = /* GraphQL */ `
  mutation DeleteSubscriptions(
    $input: DeleteSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    deleteSubscriptions(input: $input, condition: $condition) {
      pk
      id
      subscriptionPlanID
      customerName
      companyName
      renewal
      emailID
      contactNumber
      companyStrength
      companyWebsite
      plan
      companyID
      parentCompanyID
      parentCompanyName
      lastBillingDate
      nextBillingDate
      billingHistory {
        paymentDate
        validFrom
        validTo
        paymentMethod
        amount
        paymentStatus
        __typename
      }
      accountStatus
      referedBy
      subdomain
      paymentDetails {
        paymentType
        upiID
        transactionID
        accountNumber
        bankName
        branch
        ifsc
        cardType
        cardHolder
        cardNumber
        expiry
        cvv
        paymentAmount
        paidOn
        __typename
      }
      billingInfo {
        billingName
        billingAddress
        city
        state
        zipcode
        country
        emailAddress
        phone
        Vat
        __typename
      }
      subscriptionType
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployeeExtension = /* GraphQL */ `
  mutation CreateEmployeeExtension(
    $input: CreateEmployeeExtensionInput!
    $condition: ModelEmployeeExtensionConditionInput
  ) {
    createEmployeeExtension(input: $input, condition: $condition) {
      pk
      id
      bankDetails {
        accountHolder
        bankName
        accountNumber
        branch
        ifscCode
        esiNumber
        pfNumber
        UAN
        __typename
      }
      shiftDetails {
        shiftType
        fromDate
        toDate
        assignedBy
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployeeExtension = /* GraphQL */ `
  mutation UpdateEmployeeExtension(
    $input: UpdateEmployeeExtensionInput!
    $condition: ModelEmployeeExtensionConditionInput
  ) {
    updateEmployeeExtension(input: $input, condition: $condition) {
      pk
      id
      bankDetails {
        accountHolder
        bankName
        accountNumber
        branch
        ifscCode
        esiNumber
        pfNumber
        UAN
        __typename
      }
      shiftDetails {
        shiftType
        fromDate
        toDate
        assignedBy
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployeeExtension = /* GraphQL */ `
  mutation DeleteEmployeeExtension(
    $input: DeleteEmployeeExtensionInput!
    $condition: ModelEmployeeExtensionConditionInput
  ) {
    deleteEmployeeExtension(input: $input, condition: $condition) {
      pk
      id
      bankDetails {
        accountHolder
        bankName
        accountNumber
        branch
        ifscCode
        esiNumber
        pfNumber
        UAN
        __typename
      }
      shiftDetails {
        shiftType
        fromDate
        toDate
        assignedBy
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGeneralSettings = /* GraphQL */ `
  mutation CreateGeneralSettings(
    $input: CreateGeneralSettingsInput!
    $condition: ModelGeneralSettingsConditionInput
  ) {
    createGeneralSettings(input: $input, condition: $condition) {
      pk
      id
      companyID
      employeePF
      employeeESI
      TDS
      overtimeRate
      normalOvertimeRenumeration
      normalOvertimeRenumerationRate
      holidayOvertimeRenumeration
      holidayOvertimeRenumerationRate
      calendarBased
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGeneralSettings = /* GraphQL */ `
  mutation UpdateGeneralSettings(
    $input: UpdateGeneralSettingsInput!
    $condition: ModelGeneralSettingsConditionInput
  ) {
    updateGeneralSettings(input: $input, condition: $condition) {
      pk
      id
      companyID
      employeePF
      employeeESI
      TDS
      overtimeRate
      normalOvertimeRenumeration
      normalOvertimeRenumerationRate
      holidayOvertimeRenumeration
      holidayOvertimeRenumerationRate
      calendarBased
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGeneralSettings = /* GraphQL */ `
  mutation DeleteGeneralSettings(
    $input: DeleteGeneralSettingsInput!
    $condition: ModelGeneralSettingsConditionInput
  ) {
    deleteGeneralSettings(input: $input, condition: $condition) {
      pk
      id
      companyID
      employeePF
      employeeESI
      TDS
      overtimeRate
      normalOvertimeRenumeration
      normalOvertimeRenumerationRate
      holidayOvertimeRenumeration
      holidayOvertimeRenumerationRate
      calendarBased
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGeneralInterviewProcess = /* GraphQL */ `
  mutation CreateGeneralInterviewProcess(
    $input: CreateGeneralInterviewProcessInput!
    $condition: ModelGeneralInterviewProcessConditionInput
  ) {
    createGeneralInterviewProcess(input: $input, condition: $condition) {
      pk
      id
      interviewProcess
      interviewType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGeneralInterviewProcess = /* GraphQL */ `
  mutation UpdateGeneralInterviewProcess(
    $input: UpdateGeneralInterviewProcessInput!
    $condition: ModelGeneralInterviewProcessConditionInput
  ) {
    updateGeneralInterviewProcess(input: $input, condition: $condition) {
      pk
      id
      interviewProcess
      interviewType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGeneralInterviewProcess = /* GraphQL */ `
  mutation DeleteGeneralInterviewProcess(
    $input: DeleteGeneralInterviewProcessInput!
    $condition: ModelGeneralInterviewProcessConditionInput
  ) {
    deleteGeneralInterviewProcess(input: $input, condition: $condition) {
      pk
      id
      interviewProcess
      interviewType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGeneralAgency = /* GraphQL */ `
  mutation CreateGeneralAgency(
    $input: CreateGeneralAgencyInput!
    $condition: ModelGeneralAgencyConditionInput
  ) {
    createGeneralAgency(input: $input, condition: $condition) {
      pk
      id
      agencyName
      contact
      email
      phone
      website
      address
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGeneralAgency = /* GraphQL */ `
  mutation UpdateGeneralAgency(
    $input: UpdateGeneralAgencyInput!
    $condition: ModelGeneralAgencyConditionInput
  ) {
    updateGeneralAgency(input: $input, condition: $condition) {
      pk
      id
      agencyName
      contact
      email
      phone
      website
      address
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGeneralAgency = /* GraphQL */ `
  mutation DeleteGeneralAgency(
    $input: DeleteGeneralAgencyInput!
    $condition: ModelGeneralAgencyConditionInput
  ) {
    deleteGeneralAgency(input: $input, condition: $condition) {
      pk
      id
      agencyName
      contact
      email
      phone
      website
      address
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFeatures = /* GraphQL */ `
  mutation CreateFeatures(
    $input: CreateFeaturesInput!
    $condition: ModelFeaturesConditionInput
  ) {
    createFeatures(input: $input, condition: $condition) {
      pk
      id
      mainFeature
      subFeatures {
        featureName
        enable
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFeatures = /* GraphQL */ `
  mutation UpdateFeatures(
    $input: UpdateFeaturesInput!
    $condition: ModelFeaturesConditionInput
  ) {
    updateFeatures(input: $input, condition: $condition) {
      pk
      id
      mainFeature
      subFeatures {
        featureName
        enable
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFeatures = /* GraphQL */ `
  mutation DeleteFeatures(
    $input: DeleteFeaturesInput!
    $condition: ModelFeaturesConditionInput
  ) {
    deleteFeatures(input: $input, condition: $condition) {
      pk
      id
      mainFeature
      subFeatures {
        featureName
        enable
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCompanyDocuments = /* GraphQL */ `
  mutation CreateCompanyDocuments(
    $input: CreateCompanyDocumentsInput!
    $condition: ModelCompanyDocumentsConditionInput
  ) {
    createCompanyDocuments(input: $input, condition: $condition) {
      pk
      id
      docTypeName
      registrationNumber
      expiryDate
      docUrl
      docName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompanyDocuments = /* GraphQL */ `
  mutation UpdateCompanyDocuments(
    $input: UpdateCompanyDocumentsInput!
    $condition: ModelCompanyDocumentsConditionInput
  ) {
    updateCompanyDocuments(input: $input, condition: $condition) {
      pk
      id
      docTypeName
      registrationNumber
      expiryDate
      docUrl
      docName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompanyDocuments = /* GraphQL */ `
  mutation DeleteCompanyDocuments(
    $input: DeleteCompanyDocumentsInput!
    $condition: ModelCompanyDocumentsConditionInput
  ) {
    deleteCompanyDocuments(input: $input, condition: $condition) {
      pk
      id
      docTypeName
      registrationNumber
      expiryDate
      docUrl
      docName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBackGroundVerification = /* GraphQL */ `
  mutation CreateBackGroundVerification(
    $input: CreateBackGroundVerificationInput!
    $condition: ModelBackGroundVerificationConditionInput
  ) {
    createBackGroundVerification(input: $input, condition: $condition) {
      pk
      id
      sk
      applicantEmail
      applicantPhone
      agencyName
      verificationProcess {
        verficationName
        checkVerification
        __typename
      }
      document {
        docUrl
        docName
        docStatus
        reasonForFailure
        __typename
      }
      remarks
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBackGroundVerification = /* GraphQL */ `
  mutation UpdateBackGroundVerification(
    $input: UpdateBackGroundVerificationInput!
    $condition: ModelBackGroundVerificationConditionInput
  ) {
    updateBackGroundVerification(input: $input, condition: $condition) {
      pk
      id
      sk
      applicantEmail
      applicantPhone
      agencyName
      verificationProcess {
        verficationName
        checkVerification
        __typename
      }
      document {
        docUrl
        docName
        docStatus
        reasonForFailure
        __typename
      }
      remarks
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBackGroundVerification = /* GraphQL */ `
  mutation DeleteBackGroundVerification(
    $input: DeleteBackGroundVerificationInput!
    $condition: ModelBackGroundVerificationConditionInput
  ) {
    deleteBackGroundVerification(input: $input, condition: $condition) {
      pk
      id
      sk
      applicantEmail
      applicantPhone
      agencyName
      verificationProcess {
        verficationName
        checkVerification
        __typename
      }
      document {
        docUrl
        docName
        docStatus
        reasonForFailure
        __typename
      }
      remarks
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobPost = /* GraphQL */ `
  mutation CreateJobPost(
    $input: CreateJobPostInput!
    $condition: ModelJobPostConditionInput
  ) {
    createJobPost(input: $input, condition: $condition) {
      pk
      id
      requesterId
      requesterName
      companyName
      department
      jobId
      jobTitle
      designation
      jobType
      jobDescription
      responsibilities
      skills
      grade
      startDate
      closingDate
      yearsOfExperience
      jobLocation
      referralSite
      noOfVacancies
      workType
      minSalary
      maxSalary
      trainingCost
      otp
      requestStatus
      medium
      openPosition
      applicants
      shortlist
      onProcess
      rejected
      hired
      interviewProcess {
        level
        roundName
        interviewerID
        interviewerName
        interviewerRole
        interviewType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJobPost = /* GraphQL */ `
  mutation UpdateJobPost(
    $input: UpdateJobPostInput!
    $condition: ModelJobPostConditionInput
  ) {
    updateJobPost(input: $input, condition: $condition) {
      pk
      id
      requesterId
      requesterName
      companyName
      department
      jobId
      jobTitle
      designation
      jobType
      jobDescription
      responsibilities
      skills
      grade
      startDate
      closingDate
      yearsOfExperience
      jobLocation
      referralSite
      noOfVacancies
      workType
      minSalary
      maxSalary
      trainingCost
      otp
      requestStatus
      medium
      openPosition
      applicants
      shortlist
      onProcess
      rejected
      hired
      interviewProcess {
        level
        roundName
        interviewerID
        interviewerName
        interviewerRole
        interviewType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJobPost = /* GraphQL */ `
  mutation DeleteJobPost(
    $input: DeleteJobPostInput!
    $condition: ModelJobPostConditionInput
  ) {
    deleteJobPost(input: $input, condition: $condition) {
      pk
      id
      requesterId
      requesterName
      companyName
      department
      jobId
      jobTitle
      designation
      jobType
      jobDescription
      responsibilities
      skills
      grade
      startDate
      closingDate
      yearsOfExperience
      jobLocation
      referralSite
      noOfVacancies
      workType
      minSalary
      maxSalary
      trainingCost
      otp
      requestStatus
      medium
      openPosition
      applicants
      shortlist
      onProcess
      rejected
      hired
      interviewProcess {
        level
        roundName
        interviewerID
        interviewerName
        interviewerRole
        interviewType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmailTemplates = /* GraphQL */ `
  mutation CreateEmailTemplates(
    $input: CreateEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    createEmailTemplates(input: $input, condition: $condition) {
      pk
      id
      templateTitle
      emailContent
      subject
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmailTemplates = /* GraphQL */ `
  mutation UpdateEmailTemplates(
    $input: UpdateEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    updateEmailTemplates(input: $input, condition: $condition) {
      pk
      id
      templateTitle
      emailContent
      subject
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmailTemplates = /* GraphQL */ `
  mutation DeleteEmailTemplates(
    $input: DeleteEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    deleteEmailTemplates(input: $input, condition: $condition) {
      pk
      id
      templateTitle
      emailContent
      subject
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createResumeBank = /* GraphQL */ `
  mutation CreateResumeBank(
    $input: CreateResumeBankInput!
    $condition: ModelResumeBankConditionInput
  ) {
    createResumeBank(input: $input, condition: $condition) {
      pk
      id
      name
      email
      phone
      qualification
      jobTitle
      keywords
      searchKey
      resumeName
      resumeUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateResumeBank = /* GraphQL */ `
  mutation UpdateResumeBank(
    $input: UpdateResumeBankInput!
    $condition: ModelResumeBankConditionInput
  ) {
    updateResumeBank(input: $input, condition: $condition) {
      pk
      id
      name
      email
      phone
      qualification
      jobTitle
      keywords
      searchKey
      resumeName
      resumeUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteResumeBank = /* GraphQL */ `
  mutation DeleteResumeBank(
    $input: DeleteResumeBankInput!
    $condition: ModelResumeBankConditionInput
  ) {
    deleteResumeBank(input: $input, condition: $condition) {
      pk
      id
      name
      email
      phone
      qualification
      jobTitle
      keywords
      searchKey
      resumeName
      resumeUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createApplicants = /* GraphQL */ `
  mutation CreateApplicants(
    $input: CreateApplicantsInput!
    $condition: ModelApplicantsConditionInput
  ) {
    createApplicants(input: $input, condition: $condition) {
      pk
      id
      sk
      jobID
      designation
      aadharNo
      department
      reasonForReject
      candidateName
      experience
      grade
      email
      phone
      resumeName
      resumeUrl
      probation
      jobMatch
      aboutEmployee
      referralName
      referralID
      referralDepartment
      referralDesignation
      history {
        status
        approvedBy
        approvedOn
        __typename
      }
      applicationStatus
      currentCtc
      expectedCtc
      currentLocation
      description
      fresher
      workExperience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      skills
      location
      screeningDetails {
        screeningType
        interviewType
        date
        time
        duration
        eventTitle
        conductedBy
        institution
        medium
        notes
        documentName
        documentUrl
        subject
        message
        agency
        password
        cc
        emailContent
        reasonForOverride
        verificationProcess
        screeningStatus
        updatedAt
        __typename
      }
      interviewProcess {
        level
        roundName
        interviewerID
        interviewerName
        interviewerRole
        interviewType
        __typename
      }
      previousDetails {
        jobTitle
        reasonForReject
        appliedOn
        __typename
      }
      previouslyBlacklisted
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateApplicants = /* GraphQL */ `
  mutation UpdateApplicants(
    $input: UpdateApplicantsInput!
    $condition: ModelApplicantsConditionInput
  ) {
    updateApplicants(input: $input, condition: $condition) {
      pk
      id
      sk
      jobID
      designation
      aadharNo
      department
      reasonForReject
      candidateName
      experience
      grade
      email
      phone
      resumeName
      resumeUrl
      probation
      jobMatch
      aboutEmployee
      referralName
      referralID
      referralDepartment
      referralDesignation
      history {
        status
        approvedBy
        approvedOn
        __typename
      }
      applicationStatus
      currentCtc
      expectedCtc
      currentLocation
      description
      fresher
      workExperience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      skills
      location
      screeningDetails {
        screeningType
        interviewType
        date
        time
        duration
        eventTitle
        conductedBy
        institution
        medium
        notes
        documentName
        documentUrl
        subject
        message
        agency
        password
        cc
        emailContent
        reasonForOverride
        verificationProcess
        screeningStatus
        updatedAt
        __typename
      }
      interviewProcess {
        level
        roundName
        interviewerID
        interviewerName
        interviewerRole
        interviewType
        __typename
      }
      previousDetails {
        jobTitle
        reasonForReject
        appliedOn
        __typename
      }
      previouslyBlacklisted
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteApplicants = /* GraphQL */ `
  mutation DeleteApplicants(
    $input: DeleteApplicantsInput!
    $condition: ModelApplicantsConditionInput
  ) {
    deleteApplicants(input: $input, condition: $condition) {
      pk
      id
      sk
      jobID
      designation
      aadharNo
      department
      reasonForReject
      candidateName
      experience
      grade
      email
      phone
      resumeName
      resumeUrl
      probation
      jobMatch
      aboutEmployee
      referralName
      referralID
      referralDepartment
      referralDesignation
      history {
        status
        approvedBy
        approvedOn
        __typename
      }
      applicationStatus
      currentCtc
      expectedCtc
      currentLocation
      description
      fresher
      workExperience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      skills
      location
      screeningDetails {
        screeningType
        interviewType
        date
        time
        duration
        eventTitle
        conductedBy
        institution
        medium
        notes
        documentName
        documentUrl
        subject
        message
        agency
        password
        cc
        emailContent
        reasonForOverride
        verificationProcess
        screeningStatus
        updatedAt
        __typename
      }
      interviewProcess {
        level
        roundName
        interviewerID
        interviewerName
        interviewerRole
        interviewType
        __typename
      }
      previousDetails {
        jobTitle
        reasonForReject
        appliedOn
        __typename
      }
      previouslyBlacklisted
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createInterview = /* GraphQL */ `
  mutation CreateInterview(
    $input: CreateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    createInterview(input: $input, condition: $condition) {
      pk
      id
      sk
      applicantID
      screeningType
      interviewType
      interviewAssessment {
        assessment
        ratings
        __typename
      }
      interviewPreperation {
        questions
        ratings
        __typename
      }
      remarks
      status
      duration
      score
      eligibility
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateInterview = /* GraphQL */ `
  mutation UpdateInterview(
    $input: UpdateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    updateInterview(input: $input, condition: $condition) {
      pk
      id
      sk
      applicantID
      screeningType
      interviewType
      interviewAssessment {
        assessment
        ratings
        __typename
      }
      interviewPreperation {
        questions
        ratings
        __typename
      }
      remarks
      status
      duration
      score
      eligibility
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteInterview = /* GraphQL */ `
  mutation DeleteInterview(
    $input: DeleteInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    deleteInterview(input: $input, condition: $condition) {
      pk
      id
      sk
      applicantID
      screeningType
      interviewType
      interviewAssessment {
        assessment
        ratings
        __typename
      }
      interviewPreperation {
        questions
        ratings
        __typename
      }
      remarks
      status
      duration
      score
      eligibility
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSocialMediaIntegration = /* GraphQL */ `
  mutation CreateSocialMediaIntegration(
    $input: CreateSocialMediaIntegrationInput!
    $condition: ModelSocialMediaIntegrationConditionInput
  ) {
    createSocialMediaIntegration(input: $input, condition: $condition) {
      pk
      id
      socialMedia
      url
      accessToken
      logoName
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSocialMediaIntegration = /* GraphQL */ `
  mutation UpdateSocialMediaIntegration(
    $input: UpdateSocialMediaIntegrationInput!
    $condition: ModelSocialMediaIntegrationConditionInput
  ) {
    updateSocialMediaIntegration(input: $input, condition: $condition) {
      pk
      id
      socialMedia
      url
      accessToken
      logoName
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSocialMediaIntegration = /* GraphQL */ `
  mutation DeleteSocialMediaIntegration(
    $input: DeleteSocialMediaIntegrationInput!
    $condition: ModelSocialMediaIntegrationConditionInput
  ) {
    deleteSocialMediaIntegration(input: $input, condition: $condition) {
      pk
      id
      socialMedia
      url
      accessToken
      logoName
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createHiredEmployeeDetails = /* GraphQL */ `
  mutation CreateHiredEmployeeDetails(
    $input: CreateHiredEmployeeDetailsInput!
    $condition: ModelHiredEmployeeDetailsConditionInput
  ) {
    createHiredEmployeeDetails(input: $input, condition: $condition) {
      pk
      id
      title
      employeeName
      empImage
      empImageName
      companyName
      designationId
      designationName
      departmentId
      departmentName
      DOJ
      officialEmail
      otherDetails {
        aadharNumber
        aadharDocName
        aadharDocUrl
        panNumber
        panDocName
        panDocUrl
        passportNumber
        passportDocName
        passportDocUrl
        drivingLicence
        licenceExpiry
        insuranceNumber
        renewalPeriod
        verificationDoneBy
        verificationDate
        reportUrl
        reportDocName
        __typename
      }
      fresher
      probationCompleted
      probationCompletedOn
      probation
      experience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      education {
        courseTitle
        subject
        institute
        college
        durationFrom
        durationTo
        educationType
        documentUrl
        documentName
        courseType
        percentage
        __typename
      }
      status
      bloodGroup
      dob
      gender
      nationality
      mobNumber
      alternateMobNumber
      primaryContactName
      primaryContactRelation
      primaryContactNumber
      secondaryContactName
      secondaryContactRelation
      secondaryContactNumber
      marriedStatus
      language {
        languageName
        read
        write
        speak
        native
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      selfService
      mandatoryPasswordReset
      shift
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateHiredEmployeeDetails = /* GraphQL */ `
  mutation UpdateHiredEmployeeDetails(
    $input: UpdateHiredEmployeeDetailsInput!
    $condition: ModelHiredEmployeeDetailsConditionInput
  ) {
    updateHiredEmployeeDetails(input: $input, condition: $condition) {
      pk
      id
      title
      employeeName
      empImage
      empImageName
      companyName
      designationId
      designationName
      departmentId
      departmentName
      DOJ
      officialEmail
      otherDetails {
        aadharNumber
        aadharDocName
        aadharDocUrl
        panNumber
        panDocName
        panDocUrl
        passportNumber
        passportDocName
        passportDocUrl
        drivingLicence
        licenceExpiry
        insuranceNumber
        renewalPeriod
        verificationDoneBy
        verificationDate
        reportUrl
        reportDocName
        __typename
      }
      fresher
      probationCompleted
      probationCompletedOn
      probation
      experience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      education {
        courseTitle
        subject
        institute
        college
        durationFrom
        durationTo
        educationType
        documentUrl
        documentName
        courseType
        percentage
        __typename
      }
      status
      bloodGroup
      dob
      gender
      nationality
      mobNumber
      alternateMobNumber
      primaryContactName
      primaryContactRelation
      primaryContactNumber
      secondaryContactName
      secondaryContactRelation
      secondaryContactNumber
      marriedStatus
      language {
        languageName
        read
        write
        speak
        native
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      selfService
      mandatoryPasswordReset
      shift
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteHiredEmployeeDetails = /* GraphQL */ `
  mutation DeleteHiredEmployeeDetails(
    $input: DeleteHiredEmployeeDetailsInput!
    $condition: ModelHiredEmployeeDetailsConditionInput
  ) {
    deleteHiredEmployeeDetails(input: $input, condition: $condition) {
      pk
      id
      title
      employeeName
      empImage
      empImageName
      companyName
      designationId
      designationName
      departmentId
      departmentName
      DOJ
      officialEmail
      otherDetails {
        aadharNumber
        aadharDocName
        aadharDocUrl
        panNumber
        panDocName
        panDocUrl
        passportNumber
        passportDocName
        passportDocUrl
        drivingLicence
        licenceExpiry
        insuranceNumber
        renewalPeriod
        verificationDoneBy
        verificationDate
        reportUrl
        reportDocName
        __typename
      }
      fresher
      probationCompleted
      probationCompletedOn
      probation
      experience {
        companyName
        designation
        address
        durationFrom
        durationTo
        annualSalary
        currentCompany
        __typename
      }
      education {
        courseTitle
        subject
        institute
        college
        durationFrom
        durationTo
        educationType
        documentUrl
        documentName
        courseType
        percentage
        __typename
      }
      status
      bloodGroup
      dob
      gender
      nationality
      mobNumber
      alternateMobNumber
      primaryContactName
      primaryContactRelation
      primaryContactNumber
      secondaryContactName
      secondaryContactRelation
      secondaryContactNumber
      marriedStatus
      language {
        languageName
        read
        write
        speak
        native
        __typename
      }
      address {
        address1
        address2
        city
        state
        stateCode
        zip
        country
        email
        fullName
        phone
        streetOrCity
        pincode
        addressType
        __typename
      }
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      selfService
      mandatoryPasswordReset
      shift
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobOffer = /* GraphQL */ `
  mutation CreateJobOffer(
    $input: CreateJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    createJobOffer(input: $input, condition: $condition) {
      pk
      id
      department
      designation
      startDate
      location
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      CTC
      deadline
      message
      terms
      confirmationDate
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJobOffer = /* GraphQL */ `
  mutation UpdateJobOffer(
    $input: UpdateJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    updateJobOffer(input: $input, condition: $condition) {
      pk
      id
      department
      designation
      startDate
      location
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      CTC
      deadline
      message
      terms
      confirmationDate
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJobOffer = /* GraphQL */ `
  mutation DeleteJobOffer(
    $input: DeleteJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    deleteJobOffer(input: $input, condition: $condition) {
      pk
      id
      department
      designation
      startDate
      location
      earnings {
        earningType
        earningAmount
        __typename
      }
      deduction {
        deductionType
        deductionAmount
        __typename
      }
      totalEarnings
      totalDeduction
      CTC
      deadline
      message
      terms
      confirmationDate
      document {
        docUrl
        docName
        docVerify
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTokenDetails = /* GraphQL */ `
  mutation CreateTokenDetails(
    $input: CreateTokenDetailsInput!
    $condition: ModelTokenDetailsConditionInput
  ) {
    createTokenDetails(input: $input, condition: $condition) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTokenDetails = /* GraphQL */ `
  mutation UpdateTokenDetails(
    $input: UpdateTokenDetailsInput!
    $condition: ModelTokenDetailsConditionInput
  ) {
    updateTokenDetails(input: $input, condition: $condition) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTokenDetails = /* GraphQL */ `
  mutation DeleteTokenDetails(
    $input: DeleteTokenDetailsInput!
    $condition: ModelTokenDetailsConditionInput
  ) {
    deleteTokenDetails(input: $input, condition: $condition) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDemoBook = /* GraphQL */ `
  mutation CreateDemoBook(
    $input: CreateDemoBookInput!
    $condition: ModelDemoBookConditionInput
  ) {
    createDemoBook(input: $input, condition: $condition) {
      pk
      id
      name
      noOfEmployees
      email
      requirements
      phoneNumber
      companyName
      country
      searchKey
      requestedDate
      status
      emailSent
      emailSentBy
      demoDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDemoBook = /* GraphQL */ `
  mutation UpdateDemoBook(
    $input: UpdateDemoBookInput!
    $condition: ModelDemoBookConditionInput
  ) {
    updateDemoBook(input: $input, condition: $condition) {
      pk
      id
      name
      noOfEmployees
      email
      requirements
      phoneNumber
      companyName
      country
      searchKey
      requestedDate
      status
      emailSent
      emailSentBy
      demoDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDemoBook = /* GraphQL */ `
  mutation DeleteDemoBook(
    $input: DeleteDemoBookInput!
    $condition: ModelDemoBookConditionInput
  ) {
    deleteDemoBook(input: $input, condition: $condition) {
      pk
      id
      name
      noOfEmployees
      email
      requirements
      phoneNumber
      companyName
      country
      searchKey
      requestedDate
      status
      emailSent
      emailSentBy
      demoDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createContactUs = /* GraphQL */ `
  mutation CreateContactUs(
    $input: CreateContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    createContactUs(input: $input, condition: $condition) {
      pk
      id
      name
      email
      phoneNumber
      companyName
      message
      status
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateContactUs = /* GraphQL */ `
  mutation UpdateContactUs(
    $input: UpdateContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    updateContactUs(input: $input, condition: $condition) {
      pk
      id
      name
      email
      phoneNumber
      companyName
      message
      status
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteContactUs = /* GraphQL */ `
  mutation DeleteContactUs(
    $input: DeleteContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    deleteContactUs(input: $input, condition: $condition) {
      pk
      id
      name
      email
      phoneNumber
      companyName
      message
      status
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOtp = /* GraphQL */ `
  mutation CreateOtp(
    $input: CreateOtpInput!
    $condition: ModelOtpConditionInput
  ) {
    createOtp(input: $input, condition: $condition) {
      pk
      id
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOtp = /* GraphQL */ `
  mutation UpdateOtp(
    $input: UpdateOtpInput!
    $condition: ModelOtpConditionInput
  ) {
    updateOtp(input: $input, condition: $condition) {
      pk
      id
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOtp = /* GraphQL */ `
  mutation DeleteOtp(
    $input: DeleteOtpInput!
    $condition: ModelOtpConditionInput
  ) {
    deleteOtp(input: $input, condition: $condition) {
      pk
      id
      otp
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
