import React, { useState, useEffect, useRef } from "react";
import OtpInput from "react-otp-input";
import Modal from "react-bootstrap/Modal";
import { Amplify, API } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import { generateOtp, verifyOtp } from "../../../provider/dataProvider";
import { createDemoBook } from "../../../graphql/mutations";
import { toast, ToastContainer } from "react-toastify";

Amplify.configure(awsconfig);
const VerifyYourIdentityModal = ({
  showOtp,
  handleOtpClose,
  formValue,
  show,
  //toast,
  setShow,
  setShowOtp,
  time,
  setTime,
  startTimer,
  setResponseData,
}) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp.length < 4) return;
    setLoading(true);
    try {
      await verifyOtp(formValue?.email, otp);
      setVerificationStatus("success");
      handleOtpClose();
      setOtp("");
      setVerificationStatus(null);

      const { captcha, ...formValue_ } = formValue;
      try {
        await API.graphql({
          query: createDemoBook,
          authMode: "AWS_IAM",
          variables: {
            input: formValue_,
          },
        }).then((response) => {
          setResponseData({ data: response, severity: "success" });
        });
      } catch (err) {
        setResponseData({ data: err, severity: "error" });
      }
    } catch (error) {
      setVerificationStatus("failure");
    } finally {
      setLoading(false);
    }
  };
  const handleResendOtp = async () => {
    if (resendLoading || time.minutes !== 0 || time.seconds !== 0) return;
    setResendLoading(true);
    try {
      await generateOtp(formValue?.email);
      toast.success(`OTP has been resent successfully!`, {
        theme: "colored",
        autoClose: true,
      });
      setTime({ minutes: 1, seconds: 30 });
      startTimer();
    } catch (error) {
      toast.error("Failed to resend OTP. Please try again.", {
        theme: "colored",
        autoClose: true,
      });
    } finally {
      setResendLoading(false);
    }
  };

  useEffect(() => {
    if (verificationStatus === "failure") {
      const timer = setTimeout(() => {
        setVerificationStatus(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [verificationStatus]);

  const handleChangeClicked = () => {
    setShow(true);
    setShowOtp(false);
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <Modal
        show={showOtp}
        onHide={handleOtpClose}
        centered
        size="md"
        id="verifyYourIdentityModalToggle"
      >
        <Modal.Header>
          <i
            className="fas fa-times"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleOtpClose}
          ></i>
        </Modal.Header>
        <Modal.Body className="ps-5 pe-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <h5 className="mb-3">Verify Your Identity</h5>
                <p>
                  Please enter the One-Time Password (OTP) sent to
                  <span className="email-span">{formValue?.email}</span>. This
                  helps us ensure the security of your account
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleChangeClicked}
                  >
                    Change
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="w-50 m-auto">
                <div className="otp-input">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                {verificationStatus === "failure" && (
                  <div className="text-danger text-center mt-2">
                    Verification failed. Please try again.
                  </div>
                )}

                <div className="resend-otp-btn d-flex justify-content-between align-items-center mt-2">
                  <button
                    type="button"
                    className="btn btn-link ms-1"
                    onClick={handleResendOtp}
                    disabled={
                      resendLoading || time.minutes !== 0 || time.seconds !== 0
                    }
                  >
                    Resend OTP
                  </button>
                  <p className="me-3">
                    {time.minutes < 10 ? `0${time.minutes}` : time.minutes}:
                    {time.seconds < 10 ? `0${time.seconds}` : time.seconds}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer pt-0">
          <button
            className="btn book-demo-btn mb-3 pt-0"
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
            <span className="material-symbols-rounded">arrow_forward</span>
          </button>
          {verificationStatus === "success" && (
            <div className="text-success">OTP verified successfully.</div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default VerifyYourIdentityModal;
