import React, { useState, useEffect } from "react";

import BookDemoForm from "./bookDemoForm";
import { validateForm } from "./bookDemoValidation";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { generateOtp } from "../../provider/dataProvider";
import { listCountryDetails } from "../../graphql/queries";

Amplify.configure(awsconfig);
const BookDemo = ({
  setShow,
  setShowOtp,
  time,
  setTime,
  startTimer,
  formValue,
  setFormValue,
}) => {
  const [generatedCaptcha, setGeneratedCaptcha] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const employeeOptions = [
    "Less than 10 Employees",
    "10 to 25 Employees",
    "25 to 50 Employees",
    "50 to 100 Employees",
    "More than 100 Employees",
  ];

  const [formErrors, setFormErrors] = useState({});
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await API.graphql({
          query: listCountryDetails,
          authMode: "API_KEY",
        });
        const countryList = response.data.listCountryDetails.items.map(
          (item) => item.country
        );
        setCountries(countryList);
      } catch (error) {
        console.error("Error fetching country details:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const errors = validateForm(formValue, generatedCaptcha);
    setIsFormValid(Object.keys(errors).length === 0);
  }, [formValue, generatedCaptcha]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const fieldErrors = validateForm(
      { ...formValue, [name]: value },
      generatedCaptcha,
      name
    );

    // Remove the error message if the field is valid
    if (!fieldErrors[name]) {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ...fieldErrors,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formValue, generatedCaptcha);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      try {
        setShow(false);
        setShowOtp(true);
        setTime({ minutes: 1, seconds: 30 });
        startTimer();
        await generateOtp(formValue?.email);
        setFormValue(formValue);
      } catch (error) {
        console.error("Error occurred while resending OTP:", error);
      }
    }
  };

  return (
    <BookDemoForm
      time={time}
      setTime={setTime}
      formValue={formValue}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      formErrors={formErrors}
      countries={countries}
      employeeOptions={employeeOptions}
      setGeneratedCaptcha={setGeneratedCaptcha}
      isFormValid={isFormValid}
    />
  );
};

export default BookDemo;
